import * as React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PlansCardSimple from "./common/PlansCard";
import Box from "@mui/material/Box";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./Plans.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AnnouncementIcon from "@mui/icons-material/Announcement";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { MarkunreadMailbox } from "@mui/icons-material";

export default function Plans() {
  const [selectedPlan, setSelectedPlan] = React.useState("monthly");
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const getPlans = useQuery(["plans"], async () => {
    const { data } = await axios.get(`/subscription_package`);
    const plans = {
      associateDentist: {
        monthly: data.filter(
          (plan: any) =>
            plan.category === "Associate Dentist" &&
            plan.billing_type === "monthly"
        ),
        annually: data.filter(
          (plan: any) =>
            plan.category === "Associate Dentist" &&
            plan.billing_type === "annually"
        ),
      },
      practiceOwner: {
        monthly: data.filter(
          (plan: any) =>
            plan.category === "Practice Owner" &&
            plan.billing_type === "monthly"
        ),
        annually: data.filter(
          (plan: any) =>
            plan.category === "Practice Owner" &&
            plan.billing_type === "annually"
        ),
      },
    };
    return plans;
  });

  const selectPlan = useMutation(
    (id: number) => {
      return axios.post(`/user_purchase`, { subscription_package_id: id });
    },
    {
      onSuccess: (data) => {
        if (!data.data) {
          setOpen(true);
        } else {
          getStripeLink.mutate(data.data);
        }
      },
      onError: () => {
        toast.error("Something went wrong, please try again.");
      },
    }
  );

  const getMyPlanLink = useQuery(
    ["myPlanLink"],
    async () => {
      try {
        const { data } = await axios.get(
          `/user_purchase/get_stripe_customer_portal_link`
        );
        return data;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return null;
        } else {
          throw error;
        }
      }
    },
    {
      enabled:
        !!getPlans?.data?.associateDentist?.monthly?.[0]?.id ||
        !!getPlans?.data?.practiceOwner?.monthly?.[0]?.id,
    }
  );

  const getStripeLink = useMutation(
    (id: number) => {
      return axios.get(`/user_purchase/get_stripe_payment_link/${id}`);
    },
    {
      onSuccess: (data) => {
        toast.success("Plan selected successfully!");
        window.location.replace(data.data);
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          setOpen(true);
        } else {
          toast.error("Something went wrong, please try again.");
        }
      },
    }
  );

  const [bannerMessage, setBannerMessage] = useState("");
  const [user, setUser] = React.useState("");
  React.useEffect(() => {
    getUser();
  }, []);

  React.useEffect(() => {
    if (user === 1) {
      setBannerMessage(
        "You don't have the necessary permissions to purchase a plan. Please contact your administrator for assistance with purchasing a plan."
      );
    } else {
      setBannerMessage("");
    }
  }, [user]);

  const getUser = async () => {
    try {
      const { data } = await axios.get("/user");
      setUser(data[1].dentally_permission_level);

      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <div className={styles.plans}>
      {bannerMessage && (
        <div
          className={styles.banner}
          dangerouslySetInnerHTML={{ __html: bannerMessage }}
        />
      )}
      <h1 className={styles.title}>
        Select Plan:{" "}
        <span className={styles.smallText}>
          <i>this will begin after your 4 week trial</i>
        </span>
      </h1>
      <ToggleButtonGroup
        color="primary"
        value={selectedPlan}
        exclusive
        onChange={(_, v) => setSelectedPlan(v)}
        className={styles.plansToggle}
      >
        <ToggleButton value="monthly">Billed Monthly</ToggleButton>
        <ToggleButton value="annually">Billed Annually</ToggleButton>
      </ToggleButtonGroup>
      {selectedPlan && (
        <Box className={styles.cardLayout}>
          <PlansCardSimple
            title="Coming Soon:Associate Dentist"
            features={[
              "Multi practice access",
              "Unlimited Consent Bundles",
              "Unlimited Consent Clauses",
            ]}
            // actions={
            //     user === 2
            //         ? [{ label: "Purchase", action: () => selectPlan.mutate(getPlans?.data?.associateDentist?.[selectedPlan]?.[0]?.id) }]
            //         : []
            // }
            actions={[{ label: "Purchase", action: () => {}, disabled: true }]}
            price={
              user === 1
                ? ""
                : `£${
                    getPlans?.data?.associateDentist?.[selectedPlan]?.[0]
                      ?.discounted_price ||
                    getPlans?.data?.associateDentist?.[selectedPlan]?.[0]?.price
                  }/month`
            }
            oldPrice={
              user === 1 ||
              !getPlans?.data?.associateDentist?.[selectedPlan]?.[0]
                ?.discounted_price
                ? ""
                : `£${getPlans?.data?.associateDentist?.[selectedPlan]?.[0]?.price}/month`
            }
          />

          <PlansCardSimple
            title="Practice Packages"
            features={[
              "Unlimited Consents,",
              "Personalised per dentist.",
              "Unlimited Receptionist access",
            ]}
            actions={
              getPlans?.data?.practiceOwner?.[selectedPlan]?.map(
                (plan: any) => ({
                  label: `${plan.max_no_of_sites} Sites - £${
                    plan.discounted_price || plan.price
                  }/month`,
                  action: () => selectPlan.mutate(plan.id),
                })
              ) || []
            }
            price={
              user !== 1 && user !== 2
                ? selectedPlan === "annually"
                  ? "Discounted Price"
                  : "Normal Price"
                : ""
            }
            oldPrice={
              user !== 1 && user !== 2
                ? selectedPlan === "annually"
                  ? "Normal Price"
                  : ""
                : ""
            }
          />
          <PlansCardSimple
            title="Corporate/ Enterprise"
            features={[
              "Unlimited Consents,",
              "Personalised per dentist.",
              "Unlimited Receptionist access",
            ]}
            actions={[
              {
                label: "Contact Sales",
                action: () => navigate("/contact-sale"),
                disabled: user === 1 || user === 2,
              },
            ]}
            price=""
            oldPrice=""
          />
        </Box>
      )}
      <div className={styles.footer}>
        <p>
          <b>30 Day Trial. Cancel Anytime</b>
        </p>
        <p>Unlimited reception and admin team access!</p>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent style={{ textAlign: "center", borderRadius: "10px" }}>
          <img
            src="/logo.png"
            alt="Logo"
            style={{ width: "90px", borderRadius: "10%" }}
          />
          <h2>
            Your practice already has an active subscription!
            <br />
            <div style={{ textAlign: "center" }}>
              <a
                href={getMyPlanLink?.data}
                target="_blank"
                rel="noreferrer"
                className={!getMyPlanLink.data ? styles.disabled : ""}
              >
                Manage Billing
              </a>
            </div>
          </h2>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px", borderRadius: "20px" }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
