import * as React from 'react';
import { useNavigate } from "react-router-dom";
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import axios from 'axios';
import { useParams } from 'react-router-dom';

import styles from './Thankyou.module.scss';
import { Button } from '@mui/material';

export default function Thankyou() {
  let { consentBundleId } = useParams();
  let navigate = useNavigate();

  const treatmentDetails = useQuery(
    ['treatmentDetails', consentBundleId],
    async () => {
      const { data } = await axios.get(`/consent_bundle/${consentBundleId}`);
      return data;
    },
    {
      onSuccess: data => {
        if (data.status === 'Draft') {
          navigate(`/${data.data.treatment_plan_id }`)
        }
      }
    }
  );

  return (
    <div className={styles.thankyou}>
        {treatmentDetails?.data?.status !== 'Draft' && (
          <>
            <h1>Thank you</h1>
            {treatmentDetails?.data?.patient?.email_address && <p className={styles.success}>Email sent successfully to {treatmentDetails?.data?.patient?.first_name}</p>}
            <div className={styles.section}>
              <h3>Clinician Summary</h3>
              <p>You may save this summary link in your clinical notes</p>
              <div className={styles.group}>
                <p><a href={`${process.env.REACT_APP_URL}/${consentBundleId}/summary`}>{process.env.REACT_APP_URL}/{consentBundleId}/summary</a></p>
                <Button variant="outlined" onClick={() => {
                  navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/${consentBundleId}/summary`)
                  }}
                >
                  Copy summary link
                </Button>
              </div>
            </div>
            <div className={styles.section}>
              <h3>Patient Details</h3>
              {!treatmentDetails?.data?.patient?.email_address ? (
                <p className={styles.warning}>We can't find an email related to {treatmentDetails?.data?.patient?.title} {treatmentDetails?.data?.patient?.first_name} {treatmentDetails?.data?.patient?.last_name}. Please share this link with your patient</p>
              ) : (
                <p>We have shared this link with your patient to give them access to the consent documents</p>
              )}
              <div className={styles.group}>
                <p>{treatmentDetails?.data?.link || `${process.env.REACT_APP_PATIENT_URL}/${consentBundleId}`}</p>
                <Button variant="outlined" onClick={() => {
                  navigator.clipboard.writeText(treatmentDetails?.data?.link || `${process.env.REACT_APP_PATIENT_URL}/${consentBundleId}`)
                  }}
                >
                  Copy link
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  );
}