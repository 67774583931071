import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../components/CircularLoader';
import Cookies from 'js-cookie';

/**
 * `ConsentsCompletedChart` is a React component that displays a donut chart of consents completed
 * within a specified date range, for a selected practice and location. It fetches data upon initialization
 * and when relevant props change, displaying a loading indicator while fetching and a message when no data
 * matches the filters.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.location - Selected location for filtering the chart data.
 * @param {string} props.startDate - Start date for the date range filter.
 * @param {string} props.endDate - End date for the date range filter.
 */
class ConsentsCompletedChart extends React.Component {
    /**
     * Initializes the ConsentsCompletedChart component with default state.
     * @param {Object} props - The props passed to the component.
     * 
     * @state {Object} state - The internal state of the component.
     * @state {Array} state.series - The data series for the chart.
     * @state {Object} state.options - The chart configuration options.
     * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'donut',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(1) + '%';
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '13px',
                    itemMargin: {
                        horizontal: 10,
                        vertical: 5
                    }
                },
                colors: ['#626DA1', '#94BBD4', '#E1E1E1', '#FFBD88', '#FFE5E5']
            },
            isLoading: false
        };
    }

    /**
     * React lifecycle method called after the component updates. It triggers a new data fetch
     * if the practice, location, startDate, or endDate props have changed.
     * @param {Object} prevProps - Previous props for comparison.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice ||
            prevProps.location !== this.props.location ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches chart data based on the practice, location, startDate, and endDate props. Updates the component's
     * state with the new data for rendering the chart or logs an error if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });
        const { practice, location, startDate, endDate } = this.props;
        const url = `${process.env.REACT_APP_BASE_URL}admin/consents_completed?practice=${practice}&location=${location}&startDate=${startDate}&endDate=${endDate}`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
            headers: {
                Authorization: `${token}`,
            }
        };
        axios.get(url, config).then(response => {
            if (!Array.isArray(response.data)) {
                this.setState({ series: [], options: { ...this.state.options, labels: [] }, isLoading: false });
                return;
            }
            const series = response.data.map(item => item.value);
            const labels = response.data.map(item => item.label);

            this.setState({
                series,
                options: { ...this.state.options, labels },
                isLoading: false
            });

        }).catch(error => {
            console.error('Error fetching chart data:', error);
            this.setState({ isLoading: false });
        });
    }

    /**
     * React lifecycle method called immediately after the component is mounted.
     * It triggers the initial data fetch for the chart.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the donut chart using ReactApexChart with the fetched data or displays
     * a loading state while data is being fetched.
     * 
     * @returns {React.ReactElement} The rendered component, which might include a loader, an empty state message, or the chart itself.
     */
    render() {
        const { isLoading, series, options } = this.state;
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
        };

        const allValuesAreZero = this.state.series.every(value => value === 0);

        return (
            <div id="homepage-ConsentsCompletedChart" style={isLoading ? containerStyle : {}}>
                {isLoading ? (
                    <div data-testid="loader">
                        <CircularLoader />
                    </div>
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart data-testid="chart" options={options} series={series} type="donut" width={400} />
                )}
            </div>
        );
    }
}

export default ConsentsCompletedChart;
