import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../componenets/CircularLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

const pieChartOptions = {
    chart: {
        type: 'pie',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    labels: [],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(1) + '%';
        },
        offsetX: -10
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(0);
            }
        }
    },
    plotOptions: {
        pie: {
            expandOnClick: true
        }
    },
    legend: {
        position: 'bottom',
        fontSize: '13px',
        itemMargin: {
            horizontal: 10,
            vertical: 5
        }
    },
    colors: ['#626DA1', '#94BBD4', '#E1E1E1', '#FFBD88']
};

/**
 * ConsentsOutstandingPieChart is a React component that displays a pie chart
 * visualizing outstanding consents based on selected filters. The chart data
 * is dynamically fetched based on the provided props for practice, clinician,
 * and slot. This component also gracefully handles loading states and errors,
 * displaying a loader or a relevant message as needed.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - The practice selected for filtering the chart data.
 * @param {string} props.clinician - The clinician selected for filtering the chart data.
 * @param {string} props.slot - The time slot selected for filtering the chart data.
 */
class ConsentsOutstandingPieChart extends React.Component {
    /**
     * Constructor for the ConsentsOutstandingPieChart component.
     * Initializes state and binds methods, setting up initial chart options
     * and loading state.
     * @param {Object} props - Component props including `practice`, `clinician`, and `slot`.
     * 
     * @state {Array} state.series - The data series for the pie chart.
     * @state {Object} state.options - Configuration options for the pie chart.
     * @state {boolean} state.isLoading - Flag indicating whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: pieChartOptions,
            isLoading: false
        };
    }

    /**
     * Fetches chart data from the server on component update when props
     * indicating filters (practice, clinician, slot) change.
     * 
     * @param {Object} prevProps - The previous props of the component.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice || prevProps.clinician !== this.props.clinician || prevProps.slot !== this.props.slot) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches chart data from a specified API endpoint using Axios, updating the component's
     * state with the new data for rendering the chart, or handles errors if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const url = `${process.env.REACT_APP_BASE_URL}users/consents_outstanding?practice=${this.props.practice}&clinician=${this.props.clinician}&time=${this.props.slot}`;
        const token = axios.defaults.headers.common.Authorization

        const config = {
            headers: {
                "Authorization": `${token}`
            }
        };

        axios.get(url, config)
            .then(response => {
                if (!Array.isArray(response.data)) {
                    this.setState({ series: [], options: { ...this.state.options, labels: [] }, isLoading: false });
                    return;
                }

                const series = response.data.map(item => item.value);
                const labels = response.data.map(item => item.label);

                this.setState({
                    series,
                    options: { ...this.state.options, labels },
                    isLoading: false
                });
            })
            .catch(error => {
                console.error('Error fetching chart data:', error.message);
                this.setState({ isLoading: false });
            });
    }

    /**
     * Lifecycle method to fetch chart data on component mount.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the donut chart or a loading/error message as appropriate.
     * @returns {ReactNode} The React node corresponding to the component UI.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '240px'
        };

        const allValuesAreZero = this.state.series.every(value => value === 0);

        return (
            <div id="user-homepage-ConsentsOutstandingPieChart" style={this.state.isLoading ? containerStyle : {}}>
                {this.state.isLoading ? (
                    <CircularLoader />
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={400} />
                )}
            </div>
        );
    }
}

export default ConsentsOutstandingPieChart;
