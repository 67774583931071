import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../components/CircularLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

const pieChartOptions = {
    chart: {
        type: 'donut',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    labels: [],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(1) + '%';
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(1) + '%';
            }
        }
    },
    plotOptions: {
        pie: {
            expandOnClick: true
        }
    },
    legend: {
        position: 'right',
        itemMargin: {
            horizontal: 10,
            vertical: 5
        },
    },
    colors: ['#E1E1E1', '#FFBD88']
};

/**
 * `ConversionDonutChart` is a React component that displays a donut chart illustrating conversion rates.
 * It fetches chart data from an API upon component mount and updates its state to render the chart.
 * The chart visualizes conversion metrics, such as the percentage of users who have completed a specific action.
 * A loading indicator is displayed while fetching data, and a message is shown if no data matches the filters or
 * if an error occurs during data retrieval.
 */
class ConversionDonutChart extends React.Component {
    /**
     * Constructs the ConversionDonutChart component with default state.
     * Initializes chart options and sets the loading state.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: pieChartOptions,
            isLoading: false
        };
    }

    /**
     * Fetches chart data from the specified API endpoint using Axios.
     * Updates the component's state with the new data for rendering the chart
     * or sets an error state if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const url = `${process.env.REACT_APP_BASE_URL}admin/potential_conversions`;

        const token = axios.defaults.headers.common.Authorization
        const config = {
            headers: {
                Authorization: `${token}`
            }
        };

        axios.get(url, config)
            .then(response => {
                if (!Array.isArray(response.data)) {
                    this.setState({ series: [], isLoading: false });
                    return;
                }

                const newSeries = response.data.map(item => item.value);
                const newLabels = response.data.map(item => item.label);

                const updatedOptions = {
                    ...this.state.options,
                    labels: newLabels
                };

                this.setState({ series: newSeries, options: updatedOptions, isLoading: false });
            })
            .catch(error => {
                console.error('Error fetching chart data:', error);
                this.setState({ isLoading: false });
            });
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * It triggers the initial data fetching for the chart.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the donut chart using ReactApexChart with the fetched data or displays
     * a loading indicator while data is being fetched. Shows a message if no data is available.
     * 
     * @returns {React.ReactElement} The rendered component.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
        };
        const allValuesAreZero = this.state.series.every(value => value === 0);

        return (
            <div id="new-users-ConversionChart" style={this.state.isLoading ? containerStyle : {}}>
                {this.state.isLoading ? (
                    <div data-testid="loader">
                        <CircularLoader />
                    </div>
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart data-testid="chart" options={this.state.options} series={this.state.series} type="donut" width={360} />
                )}
            </div>
        );
    }
}

export default ConversionDonutChart;
