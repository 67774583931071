import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../componenets/CircularLoader';
import Cookies from "js-cookie";

const stackedColumnChartOptions = {
    chart: {
        type: 'bar',
        width: 380,
        stacked: true,
        toolbar: {
            show: false
        }
    },
    xaxis: {
        categories: []
    },
    plotOptions: {
        bar: {
            borderRadius: 10
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(0);
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(0);
            }
        }
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 0
    },
    colors: ['#626DA1', '#94BBD4', '#FFD2D7', '#FFBD88'],
    yaxis: {
        min: 0,
        tickAmount: 5,
        labels: {
            formatter: function (val) {
                return Math.round(val);
            }
        }
    }
};

/**
 * Displays a stacked column chart using ApexCharts to represent consent status breakdown.
 * Fetches chart data from an API based on provided props (practice, clinician, startDate, endDate)
 * and handles loading state internally. Shows a loader while fetching data and a message
 * when no data matches the filters.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 * @param {string} props.startDate - Start date for the date range filter.
 * @param {string} props.endDate - End date for the date range filter.
 */
class ConsentStatusChart extends React.Component {
    /**
     * Initializes the ConsentStatusChart component with default state.
     * @param {Object} props - The props passed to the component.
     * 
     * @state {Object} state - The internal state of the component.
     * @state {Array} state.series - The data series for the chart.
     * @state {Object} state.options - The chart configuration options.
     * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: stackedColumnChartOptions,
            isLoading: false
        };
    }

    /**
     * React lifecycle method that is called immediately after updating occurs.
     * Fetches new chart data if the relevant props have changed.
     * @param {Object} prevProps - The previous props before the update.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice ||
            prevProps.clinician !== this.props.clinician ||
            prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches chart data from a specified API endpoint using axios. Updates the component's
     * state with the new data for rendering the chart, or handles errors if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const { practice, clinician, startDate, endDate } = this.props;
        let url = `${process.env.REACT_APP_BASE_URL}users/consent_status_breakdown?practice=${practice}&clinician=${clinician}`;

        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`;
        }

        const token = axios.defaults.headers.common.Authorization
        const config = {
            headers: {
                "Authorization": `${token}`
            }
        };

        axios.get(url, config)
            .then(response => {
                const apiData = response.data;

                const seriesData = apiData.Series[0].Labels.map(label => ({
                    name: label,
                    data: []
                }));

                apiData.Series.forEach((series, index) => {
                    series.Values.forEach((value, valueIndex) => {
                        seriesData[valueIndex].data.push(value);
                    });
                });

                this.setState({
                    series: seriesData,
                    options: {
                        ...this.state.options,
                        xaxis: {
                            categories: apiData.Receptionist
                        }
                    },
                    isLoading: false
                });
            })
            .catch(error => {
                console.error('Error fetching chart data:', error);
                this.setState({ isLoading: false });
            });
    }


    /**
     * React lifecycle method that is called immediately after a component is mounted.
     * It triggers the initial data fetching for the chart.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the chart based on the current state. Displays a loader while data is being
     * fetched, a message if no data matches the filters, or the chart if data is available.
     * @returns {React.ReactElement} The rendered component.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
        };
        const { isLoading, options, series } = this.state;
        const allValuesAreZero = this.state.series.every(series =>
            series.data.every(value => value === 0)
        );
        return (
            <div id="user-compliance-management-ConsentStatusBreakdown" style={this.state.isLoading ? containerStyle : {}}>
                {isLoading ? (
                    <div data-testid="loader">
                        <CircularLoader />
                    </div>
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart
                        data-testid="chart"
                        options={options}
                        series={series}
                        type="bar"
                        width={340}
                        height={300}
                    />
                )}

            </div>
        );
    }
}

export default ConsentStatusChart;