import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import MainCard from '../../componenets/MainCard';
import ScoreBarChart from './ScoreBarChart';
import ConsentsOutstandingPieChart from './ConsentsOutstandingPieChart';
import ConsentsCompletedChart from './ConsentsCompletedChart';
import TimeSaved from './TimeSaved';
import TimeCouldSave from './TimeCouldSave';
import Loader from '../../componenets/Loader';
import PDFExportComponent from '../../componenets/PDFExportComponent';
import Sidebar from '../../menu-items/Sidebar';

const HomepageUser = () => {
  const [slot, setSlot] = useState('daily');
  const [practiceList, setPracticeList] = useState([]);
  const [practice, setPractice] = useState('');
  const [clinicianList, setClinicianList] = useState([]);
  const [clinician, setClinician] = useState('');
  const [clinicianName, setClinicianName] = useState('');

  const filters = {
    practice: practice,
    clinician: clinician
  };

  const chartIds = [
    { id: 'maincard_consents_outstanding', singleRow: false },
    { id: 'maincard_consents_completed', singleRow: false }
  ];

  const fileName = 'report-user-homepage.pdf';

  useEffect(() => {
    const name = Cookies.get('clinicianName');
    if (name) {
      setClinicianName(name);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            "Authorization": `${token}`
          }
        };

        const practiceResponse = await axios.get(practiceURL, config);
        if (isMounted) {
          if (practiceResponse.data && practiceResponse.data.length > 0) {
            setPracticeList(practiceResponse.data);
            setPractice(practiceResponse.data[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchClinicianList = async () => {
      try {
        if (practice) {
          const clinicianURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/clinicians?practice=${practice}`;
          const token = axios.defaults.headers.common.Authorization
          const config = {
            headers: {
              "Authorization": `${token}`
            }
          };
          
          const clinicianResponse = await axios.get(clinicianURL, config);
          if (isMounted) {
            if (clinicianResponse.data && clinicianResponse.data.length > 0) {
              setClinicianList(clinicianResponse.data);
              setClinician(clinicianResponse.data[0].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching clinician data: ', error);
      }
    };

    fetchClinicianList();
    return () => {
      isMounted = false;
    };
  }, [practice]);

  if (practiceList.length < 1 || clinicianList.length < 1) {
    return <Loader />
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Grid container spacing={2.5}>
        {/* Sidebar */}
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        {/* Main content */}
        <Grid item xs={12} md={9} lg={10} container spacing={2.5}>
          {/* Welcome and Filters */}
          <Grid item xs={12} md={5} lg={4}>
            <MainCard content={false} sx={{ height: { xs: 'auto', md: '160px' }, mb: { xs: 2, md: 0 } }}>
              <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Welcome, {clinicianName || 'User'}
                </Typography>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 2, sm: 1 }}
                  sx={{ mt: 2, width: '100%', justifyContent: 'center' }}
                >
                  <TextField
                    id="practice-filter"
                    size="small"
                    select
                    value={practice}
                    onChange={(e) => setPractice(e.target.value)}
                    sx={{ width: { xs: '100%', sm: 120 } }}
                  >
                    {practiceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="clinician-filter"
                    size="small"
                    select
                    value={clinician}
                    onChange={(e) => setClinician(e.target.value)}
                    sx={{ width: { xs: '100%', sm: 120 } }}
                  >
                    {clinicianList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Box>
            </MainCard>
          </Grid>

          {/* ScoreBarChart */}
          <Grid item xs={12} md={7} lg={8}>
            <MainCard content={false} sx={{ height: { xs: 'auto', md: '160px' } }}>
              <ScoreBarChart practice={practice} clinician={clinician} />
            </MainCard>
          </Grid>

          {/* Consents Outstanding */}
          <Grid item xs={12} md={6} lg={4}>
            <MainCard id='maincard_consents_outstanding' content={false} sx={{ height: { xs: 'auto', md: '431px' } }}>
              <Box sx={{ p: 3 }}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  Consents Outstanding
                </Typography>
                <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                  {['daily', 'weekly', 'monthly'].map((period) => (
                    <Button
                      key={period}
                      size="small"
                      onClick={() => setSlot(period)}
                      color={slot === period ? 'primary' : 'secondary'}
                      variant={slot === period ? 'outlined' : 'text'}
                    >
                      {period.charAt(0).toUpperCase() + period.slice(1)}
                    </Button>
                  ))}
                </Stack>
                <ConsentsOutstandingPieChart practice={practice} clinician={clinician} slot={slot} />
              </Box>
            </MainCard>
          </Grid>

          {/* Consents Completed */}
          <Grid item xs={12} md={6} lg={4}>
            <MainCard id='maincard_consents_completed' content={false} sx={{ height: { xs: 'auto', md: '431px' } }}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  Consents Completed
                </Typography>
                <ConsentsCompletedChart practice={practice} clinician={clinician} />
              </Box>
            </MainCard>
          </Grid>

          {/* Time Saved and Time Could Save */}
          <Grid item xs={12} md={12} lg={4} container spacing={2.5}>
            <Grid item xs={12}>
              <TimeSaved practice={practice} clinician={clinician} />
            </Grid>
            <Grid item xs={12}>
              <TimeCouldSave practice={practice} clinician={clinician} />
            </Grid>
          </Grid>

          {/* Footer */}
          <Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
            <Typography variant="caption">
              ©2024 Medicube
            </Typography>
            <PDFExportComponent filters={filters} chartIds={chartIds} fileName={fileName} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomepageUser;
