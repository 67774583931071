import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from 'js-cookie';
import CircularLoader from '../../components/CircularLoader';

/**
 * `PotentialUsersList` displays a list of potential users (either dentists or practices) based on the selected
 * filter and location. It fetches data dynamically upon component initialization and when the filter or location props
 * change. The component adapts its display based on screen size and provides an interactive list that
 * reflects the current selection criteria.
 * 
 * This component demonstrates responsive design using Material UI's useMediaQuery hook to adjust the maximum
 * height of the displayed list based on the device's screen size. It showcases the use of Material UI components
 * for building a user interface and axios for fetching data from a server. Cookies are used for
 * authentication in API requests.
 *
 * @param {Object} props - Component props.
 * @param {string} props.filter - Determines whether to display dentists or practices.
 * @param {string} props.location - The location used for filtering the data.
 * @param {string} props.size - Controls the sorting order of the list.
 * 
 * @returns {React.ReactElement} A list of potential users based on the selected filter and location.
 */
const PotentialUsersList = ({ filter, location, size }) => {
  const [dentist, setDentist] = useState([]);
  const [practice, setPractice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  let dynamicMaxHeight;
  if (isXs) dynamicMaxHeight = 500;
  else if (isMd) dynamicMaxHeight = 300;
  else if (isLg) dynamicMaxHeight = 200;

  useEffect(() => {
    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };
    const fetchPractices = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}admin/potential_users/practices?location=${location}`;
        const practiceResponse = await axios.get(practiceURL, config);
        setPractice(practiceResponse.data);
      } catch (error) {
        console.error('Error fetching practices:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDentists = async () => {
      try {
        const dentistURL = `${process.env.REACT_APP_BASE_URL}admin/potential_users/dentists?location=${location}`;
        const dentistResponse = await axios.get(dentistURL, config);
        setDentist(dentistResponse.data);
      } catch (error) {
        console.error('Error fetching dentists:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (filter === 'dentist') {
      fetchDentists();
    } else if (filter === 'practice') {
      fetchPractices();
    }
  }, [filter, location]);


  const getContent = () => {
    let listToShow = filter === 'dentist' ? [...dentist] : [...practice];

    if (size === 'ascend') {
      listToShow.reverse();
    }

    return listToShow.map((item, index) => {
      if (filter === 'dentist') {
        return (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ListItemAvatar sx={{ alignSelf: 'center' }}>
              <Avatar>{item.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.practice}
              sx={{ alignSelf: 'center' }}
            />
          </ListItem>

        );
      } else {
        return (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ListItemAvatar sx={{ alignSelf: 'center' }}>
              <Avatar>{item.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              sx={{ alignSelf: 'center' }}
            />
          </ListItem>

        );
      }
    });
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  };
  return (
    <List id='new-users-PotentialUsersList' sx={isLoading ? containerStyle : { width: '100%', maxHeight: dynamicMaxHeight, overflow: 'auto' }}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        getContent()
      )}
    </List>
  );
}

export default PotentialUsersList;