import dashboardUser from './dashboard-user';

/**
 * Retrieves the navigation menu items for the application.
 * 
 * This function compiles all navigation configurations into a single object. Currently, it
 * includes only the 'dashboard' navigation group, but it can be easily extended to include more
 * groups or items. The structure is designed to support dynamic rendering of navigation menus,
 * facilitating the addition of new navigation items with minimal changes to the codebase.
 *
 * @returns {Object} An object containing an array of navigation item groups.
 */
const menuItems = () => {
  const items = [dashboardUser];

  return { items };
};

export default menuItems;
