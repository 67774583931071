import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import MainCard from '../../componenets/MainCard';
import DatePickerCard from '../../componenets/DatePickerCard';
import ScoreBarChart from './ScoreBarChart';
import ConsentsCompletedChart from './ConsentsCompletedChart';
import ConsentStatusChart from './ConsentStatusBreakdown';
import MonthlyAverageChart from './MonthlyAverageChart';
import Loader from '../../componenets/Loader';
import PDFExportComponent from '../../componenets/PDFExportComponent';
import Cookies from "js-cookie";
import Sidebar from '../../menu-items/Sidebar';

const CompliancePageUser = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [practiceList, setPracticeList] = useState([{ label: "All practices", value: "all" }]);
  const [practice, setPractice] = useState('');
  const [clinicianList, setClinicianList] = useState([{ label: "All clinicians", value: "all" }]);
  const [clinician, setClinician] = useState('');

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filters = {
    practice: practice,
    clinician: clinician,
    startDate: startDate,
    endDate: endDate
  };
  const chartIds = [
    { id: 'maincard_consents_completed', singleRow: false },
    { id: 'maincard_consent_status_breakdown', singleRow: false },
    { id: 'maincard_average_monthly_consents_completed', singleRow: true, scale: 2 }
  ];
  const fileName = 'report-user-compliance-management.pdf';

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
        const token = axios.defaults.headers.common.Authorization;
        const config = {
          headers: {
            "Authorization": `${token}`
          }
        };

        const practiceResponse = await axios.get(practiceURL, config);
       console.log(practiceResponse.data);
        if (isMounted) {
          if (practiceResponse.data && practiceResponse.data.length > 0) {
            const updatedPracticeList = [
              {  value: "1" },
              ...practiceResponse.data
            ];
            
            setPracticeList(updatedPracticeList);
            setPractice(updatedPracticeList[1].value);
          }
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchClinicianList = async () => {
      try {
        if (practice) {
          const clinicianURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/clinicians?practice=${practice}`;
          const token = axios.defaults.headers.common.Authorization;
          const config = {
            headers: {
              "Authorization": `${token}`
            }
          };

          const clinicianResponse = await axios.get(clinicianURL, config);
          
          if (isMounted) {
            if (clinicianResponse.data && clinicianResponse.data.length > 0) {
              const updatedClinicianList = [
                { value: "1" },
                ...clinicianResponse.data
              ];
              
              setClinicianList(updatedClinicianList);
              setClinician(updatedClinicianList[1].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching clinician data: ', error);
      }
    };

    fetchClinicianList();
    return () => {
      isMounted = false;
    };
  }, [practice]);

  if (practiceList.length <= 1 || clinicianList.length <= 1) {
    return <Loader />;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Grid container spacing={2.5}>
            {/* row 1 */}
            <Grid item xs={12} md={6} lg={4}>
              <MainCard content={false} sx={{ height: 'auto' }}>
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    Compliance Management
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, flexWrap: 'wrap', maxWidth: 600, margin: 'auto', mt: 2 }}>
                  <TextField
                    id="practice-filter"
                    size="small"
                    select
                    value={practice}
                    onChange={(e) => setPractice(e.target.value)}
                    sx={{ width: 150, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {practiceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="clinician-filter"
                    size="small"
                    select
                    value={clinician}
                    onChange={(e) => setClinician(e.target.value)}
                    sx={{ width: 150, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {clinicianList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, flexWrap: 'wrap', maxWidth: 600, margin: 'auto', mt: 2 ,mb:2}}>
                  <DatePickerCard onDateChange={handleDateChange} />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <MainCard content={false} sx={{ height: 'auto' }}>
                <Box sx={{ p: 4, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    Compliance Management Score
                  </Typography>
                </Box>
                <ScoreBarChart
                  practice={practice}
                  clinician={clinician}
                  startDate={startDate}
                  endDate={endDate}
                />
              </MainCard>
            </Grid>

            {/* row 2 */}
            <Grid item xs={12} md={6} lg={4}>
              <MainCard id='maincard_consents_completed' content={false} sx={{ height: 'auto' }}>
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Consents Completed
                  </Typography>
                </Box>
                <ConsentsCompletedChart
                  practice={practice}
                  clinician={clinician}
                  startDate={startDate}
                  endDate={endDate}
                />
              </MainCard>
              <MainCard id='maincard_consent_status_breakdown' sx={{ mt: 2, height: 'auto' }} content={false}>
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Consent Status Breakdown
                  </Typography>
                </Box>
                <ConsentStatusChart
                  practice={practice}
                  clinician={clinician}
                  startDate={startDate}
                  endDate={endDate}
                />
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <MainCard id='maincard_average_monthly_consents_completed' content={false} sx={{ height: 'auto' }}>
                <Box sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Average Monthly Consents Completed
                  </Typography>
                </Box>
                <MonthlyAverageChart
                  practice={practice}
                  clinician={clinician}
                  startDate={startDate}
                  endDate={endDate}
                />
              </MainCard>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <PDFExportComponent chartIds={chartIds} fileName={fileName} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompliancePageUser;
