import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../components/CircularLoader';
import Cookies from 'js-cookie';

const initialChartOptions = {
  chart: {
    type: 'donut',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    }
  },
  labels: [],
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val.toFixed(1) + '%';
    }
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    }
  },
  plotOptions: {
    pie: {
      expandOnClick: true
    }
  },
  legend: {
    position: 'right',
    fontSize: '13px',
    itemMargin: {
      horizontal: 10,
      vertical: 5
    }
  },
  colors: ['#626DA1', '#94BBD4', '#E1E1E1', '#FFBD88', '#FFE5E5']
};

/**
 * ConsentsCompletedChart is a React component for displaying a donut chart
 * representing the completed consents. It fetches data based on the given
 * props (practice, clinician, location) and renders the chart with
 * dynamic data. It also handles loading and error states gracefully.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 * @param {string} props.location - Selected location for filtering the chart data.
 */
class ConsentsCompletedChart extends React.Component {
  /**
   * Constructor for the ConsentsCompletedChart component.
   * Initializes state and binds methods.
   * @param {Object} props - Component props including `practice`, `clinician`, and `location`.
   * 
   * @state {Object} state - The internal state of the component.
   * @state {Array} state.series - The data series for the chart.
   * @state {Object} state.options - The chart configuration options.
   * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
   */
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: initialChartOptions,
      isLoading: false
    };
  }

  /**
   * Lifecycle method to fetch chart data on component update when
   * props indicating filters (practice, clinician, location) change.
   * @param {Object} prevProps - The previous props of the component.
   */
  componentDidUpdate(prevProps) {
    if (this.props.practice !== prevProps.practice ||
      this.props.clinician !== prevProps.clinician ||
      this.props.location !== prevProps.location) {
      this.fetchChartData();
    }
  }

  /**
   * Fetches chart data from the backend API using axios and updates state
   * accordingly. Utilizes authorization token from cookies.
   */
  fetchChartData() {
    this.setState({ isLoading: true });
    const { practice, clinician, location } = this.props;
    const url = `${process.env.REACT_APP_BASE_URL}admin/consents_completed?practice=${practice}&clinician=${clinician}&location=${location}`;

    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };
    axios.get(url, config).then(response => {
      if (!Array.isArray(response.data)) {
        this.setState({ series: [], options: { ...this.state.options, labels: [] }, isLoading: false });
        return;
      }

      const series = response.data.map(item => item.value);
      const labels = response.data.map(item => item.label);
      this.setState({
        series,
        options: { ...this.state.options, labels },
        isLoading: false
      });

    }).catch(error => {
      this.setState({ isLoading: false });
      console.error('Error fetching chart data:', error);
    });
  }

  /**
   * Lifecycle method to fetch chart data on component mount.
   */
  componentDidMount() {
    this.fetchChartData();
  }
  
  /**
   * Renders the donut chart or a loading/error message as appropriate.
   * @returns {ReactNode} The React node corresponding to the component UI.
   */
  render() {
    const { isLoading, series, options } = this.state;
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '160px'
    };
    const allValuesAreZero = this.state.series.every(value => value === 0);
    return (
      <div id="compliance-management-ConsentsCompletedChart" style={isLoading ? containerStyle : {}}>
        {isLoading ? (
          <CircularLoader />
        ) : allValuesAreZero ? (
          <div style={containerStyle}>No data matches these filters.</div>
        ) : (
          <ReactApexChart options={options} series={series} type="donut" width={350} />
        )}
      </div>
    );
  }
}

export default ConsentsCompletedChart;