import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Box } from '@mui/material';
import { Field, Form } from 'react-final-form';
import { Select as RffSelect } from 'mui-rff';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_KEY = 'AIzaSyByI_l6XffzkdFkoTIOy6tThHkAcDIQNL4';

const isValidYouTubeUrl = (url) => {
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})\b/;
  return pattern.test(url);
};



const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Required';
  }
  if (!values.url) {
    errors.url = 'Required';
  } else if (!isValidYouTubeUrl(values.url)) {
    errors.url = 'Invalid YouTube URL';
  }
  return errors;
};

const getVideoDetails = async (videoId) => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoId}&key=${API_KEY}`;
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.items.length > 0) {
      const videoData = data.items[0];
      const title = videoData.snippet.title;
      const description = videoData.snippet.description;
      const thumbnail = videoData.snippet.thumbnails.high.url;
      const durationISO = videoData.contentDetails.duration;
      
      const duration = parseDuration(durationISO);

      return { title, description, thumbnail, duration };
    } else {
      throw new Error('Video not found');
    }
  } catch (error) {
    console.error('Error fetching video details:', error);
    return null;
  }
};


const extractVideoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const parseDuration = (isoDuration) => {
  const match = isoDuration.match(/PT(?:([\d]+)H)?(?:([\d]+)M)?(?:([\d]+)S)?/);
  const hours = parseInt(match[1], 10) || 0;
  const minutes = parseInt(match[2], 10) || 0;
  const seconds = parseInt(match[3], 10) || 0;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const PlaylistModal = ({ open, onClose, onSubmit, playlistId, currentCategory }) => {
  const initialFormValues = {
    title: '',
    description: '',
    url: '',
    platform: 'youtube',
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [videoDetails, setVideoDetails] = useState({ title: '', description: '', thumbnail: '', duration: '' });

  useEffect(() => {
    if (!open) {
      setFormValues(initialFormValues);
      setVideoDetails({ title: '', description: '', thumbnail: '', duration: '' });
    }
  }, [open]);

  const handleScrubVideoDetails = async (url, form) => {
    const videoId = extractVideoId(url);
    if (videoId) {
      const details = await getVideoDetails(videoId);
      if (details) {
        setVideoDetails({
          title: details.title,
          description: details.description,
          thumbnail: details.thumbnail,
          duration: details.duration,
        });
        form.change('title', details.title);
        form.change('description', details.description);
        
      } else {
        toast.error("Failed to fetch video details.");
      }
    } else {
      toast.error("Invalid YouTube URL.");
    }
  };

  const handleSubmit = async (values) => {
    const videoId = extractVideoId(values.url);

    if (videoId) {
      try {
        const newVideo = {
          title: values.title || videoDetails.title,
          description: values.description || videoDetails.description,
          url: values.url,
          platform: values.platform,
          duration: videoDetails.duration,
          type: 'mp4',
        };

        const updatedVideos = currentCategory.videos.map(({ thumbnail, ...video }) => ({
          ...video,
          type: 'mp4',
        }));

        updatedVideos.push(newVideo);
        const payload = updatedVideos;
        // console.log(payload)
        const response = await axios.put(`playlist/${playlistId}/save_playlist_items`, payload);

        if (response.status === 200) {
          const result = await response.data;
          if (result === true) {
            toast.success("Playlist item saved successfully");
          } else {
            toast.error("Failed to save playlist item.");
          }
        } else {
          toast.error("Failed to save playlist item.");
        }
        onSubmit({ ...values, duration: videoDetails.duration , thumbnail: videoDetails.thumbnail});
      } catch (error) {
        toast.error("Failed to save playlist item.");
      }
    } else {
      toast.error("Invalid YouTube URL");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          borderRadius: '15px',
          padding: '20px',
        },
      }}
    >
      <DialogTitle>Add New Playlist Item</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={formValues}
          render={({ handleSubmit, form, values }) => (
            <form
              onSubmit={(e) => {
                handleSubmit(e);
                form.reset();
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Field name="url">
                  {({ input, meta }) => (
                    <TextField
                      label="URL"
                      variant="outlined"
                      fullWidth
                      {...input}
                      onBlur={() => {
                        handleScrubVideoDetails(input.value, form);
                        setFormValues((prev) => ({ ...prev, url: input.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Field name="title">
                  {({ input, meta }) => (
                    <TextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      {...input}
                      value={values.title || videoDetails.title}
                      onChange={(e) => {
                        input.onChange(e);
                        setFormValues((prev) => ({ ...prev, title: e.target.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Field name="description">
                  {({ input, meta }) => (
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      {...input}
                      value={values.description || videoDetails.description}
                      onChange={(e) => {
                        input.onChange(e);
                        setFormValues((prev) => ({ ...prev, description: e.target.value }));
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Field name="platform">
                  {({ input }) => (
                    <RffSelect
                      {...input}
                      label="Platform"
                      required
                      formControlProps={{ margin: 'normal' }}
                    >
                      <MenuItem value="youtube">YouTube</MenuItem>
                    </RffSelect>
                  )}
                </Field>
              </Box>

              <DialogActions>
                <Button
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PlaylistModal;
