import React from "react";
import styles from "./LogoPage.module.scss";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

function LogoPage() {
  const { data, isLoading } = useQuery(
    ["isAdmin"],
    async () => {
      const { data } = await axios.get("/user/is_admin");
      return data;
    },
    {
      onSuccess: async () => {
        window.location.href = '/dashboard';
      },
    }
  );

  return (
    <div className={styles.logoPage}>
      <img src="../logo.png" alt="Logo" className={styles.logo} />
      {isLoading && <CircularProgress className={styles.loadingIndicator} />}
    </div>
  );
}

export default LogoPage;
