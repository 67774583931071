import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularLoader from '../../componenets/CircularLoader';
import Cookies from "js-cookie";
/**
 * `VideoTable` is a functional React component that displays a sortable table of videos
 * with their engagement percentages. This table allows users to understand which videos
 * have the highest engagement rates based on a specified practice and clinician.
 * The component fetches video engagement data upon initialization and when any of the 
 * specified props change. A loading indicator is shown while data is fetched, and an 
 * informative message is displayed if no data matches the selected filters or if an 
 * error occurs during data fetching.
 *
 * Sorting functionality is implemented to allow the user to sort the videos by their 
 * engagement percentages in ascending or descending order. This feature enhances the
 * usability of the table, making it easier to identify the videos with the highest
 * and lowest engagement rates.
 *
 * @param {Object} props - Props for configuring the video table.
 * @param {string} props.practice - The practice to filter the video engagement data.
 * @param {string} props.clinician - The clinician to further filter the data.
 * @returns {React.ReactElement} A table displaying video titles and their engagement 
 * percentages, along with sorting controls and a loading indicator or a message when applicable.
 */
function VideoTable({ practice, clinician, location }) {
  const [iconRotation, setIconRotation] = useState(0);
  const rotateStyle = {
    transform: `rotate(${iconRotation}deg)`,
    transition: 'transform 0.3s ease-in-out',
  };
  const sortRows = () => {
    const sortedRows = [...rows].sort((a, b) => sortAsc ? a.percentage - b.percentage : b.percentage - a.percentage);
    setRows(sortedRows);
    setSortAsc(!sortAsc);
    setIconRotation(iconRotation === 0 ? 180 : 0);
  };

  const [rows, setRows] = useState([]);
  const [sortAsc, setSortAsc] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_BASE_URL}users/average_video_engagement?practice=${practice}&clinician=${clinician}`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            "Authorization": `${token}`
          }
        };
        const response = await axios.get(url, config);
        if (response.data && Array.isArray(response.data)) {
          const newRows = response.data.map(item => ({
            title: item.label,
            percentage: parseFloat(item.value).toFixed(1)
          }));
          setRows(newRows);
        } else {
          setRows(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setRows(null);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [practice, clinician, location]);


  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
        <CircularLoader />
      </div>
    );
  }

  if (rows === null || rows.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
        No data matches these filters.
      </div>
    );
  }

  return (
    <div id="user-patient-engagement-VideoTable" style={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: '330px', overflow: 'auto' }}>
        <Table aria-label="patient-engagement-VideoTable" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: '60%' }}><Typography fontWeight="bold">Video Title</Typography></TableCell>
              <TableCell align="center" sx={{ width: '40%' }}>
                <Typography fontWeight="bold" display="inline">Percentage</Typography>
                <IconButton onClick={sortRows} size="small">
                  <ArrowDownwardIcon fontSize="inherit" style={rotateStyle} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'grey.100' } }}>
                <TableCell component="th" scope="row" align="center">
                  {row.title}
                </TableCell>
                <TableCell align="center">
                  {`${row.percentage}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default VideoTable;
