import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Modal } from '@mui/material';
import { Form } from 'react-final-form'
import * as Yup from 'yup';
import {TextField, makeValidate} from 'mui-rff';
import styles from './HelpForm.module.scss';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  mobile: Yup.string().required(),
  message: Yup.string().required(),
});
 
// Run the makeValidate function...
const validate = makeValidate(schema);

function HelpForm({ openHelp, setOpenHelp }) {

  const sendHelp = useMutation(
    (data: any) => {
        return axios.post(`/practice/send_contact_form`, data);
    },
    {
      onSuccess: () => {
        setOpenHelp(false);
      }
    }
  );

  const sendHelpData = data => {
    sendHelp.mutate(data);
  }

  return (
      <Modal
        open={openHelp}
        onClose={() => setOpenHelp(false)}
      >
        <div className="modal">
        <h2 className={styles.header}>Need Help? Contact us</h2>
          <Form
            onSubmit={sendHelpData}
            validate={validate}
            render={({ handleSubmit }) => (
            <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
                    <TextField
                        label="Email"
                        name="email"
                        required={true}
                        type="email"
                        variant="outlined"
                        className="mg-b-1"
                    />
                    <TextField
                        label="Mobile/Contact"
                        name="mobile"
                        required={true}
                        type="text"
                        variant="outlined"
                        className="mg-b-1"
                    />
                    <TextField
                        label="Message"
                        name="message"
                        required
                        type="text"
                        multiline
                        rows={4}
                        variant="outlined"
                        className={styles.textField}
                      />
                      <Box mt={1} textAlign={'right'}>
                       <Button className={styles.submitButton} type="submit" variant="contained" disabled={sendHelp.isLoading}>Send</Button>
                      </Box>
            </form>
            )}
        />
        </div>
      </Modal> 
  );
}
export default HelpForm;