import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import styles from "./Templates.module.scss";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
export default function TemplatesComponent({ closeModal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null);
  const [applyForAllPractices, setApplyForAllPractices] = useState(false);
  const [selectedSitecheck, setSelectedSitecheck] = useState(true);
  const [practiceData, setPracticeData] = useState({});
  const [practiceSites, setPracticeSites] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const fetchPracticeSites = async () => {
    try {
      const response = await axios.get(`/site/`);
      setPracticeSites(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching practice sites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPracticeSites();
  }, []);

  const handleChange = (e) => {
    const Id = e.target.value;
    setSelectedSite(Id);
    const selectedSiteObject = practiceSites.find((site) => site.id === Id);
    if (selectedSiteObject) {
      setSelectedSitecheck(false);
      getNameAndLogo(Id);
    } else {
      setSelectedSitecheck(true);
      setPracticeName("");
    }
  };

  const handleDrop = async (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    const options = {
      maxSizeMB: 1, // Maximum size
      maxWidthOrHeight: 1920, // Maximum width and height
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setUploadedFile(compressedFile);
      setUploadedImageUrl(URL.createObjectURL(compressedFile));
    } catch (error) {
      console.error("Error compressing the image:", error);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const mutation = useMutation(
    async ({ id, formData }) => {
      const response = await axios.post(
        `/site/${id}/save_site_consent_clause_data`,
        formData
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Practice name and logo uploaded successfully.");
        setUploading(false);
        closeModal();
      },
    }
  );

  const getNameAndLogo = async (Id) => {
    try {
      const response = await axios.get(
        `/site/${Id}/get_site_consent_clause_data`
      );
      setPracticeData(response.data);
      setPracticeName(response.data.display_name || "");
    } catch (error) {
      console.error("Error fetching practice data:", error);
    }
  };
  const handleSubmit = async () => {
    if (applyForAllPractices) {
      setIsConfirmationOpen(true);
    } else {
      await uploadData();
    }
  };

  const uploadData = async () => {
    if (!practiceName) {
      toast.error("Please upload an image and enter the practice name.");
      return;
    }
  
    setUploading(true);
    setUploadError("");
    let base64Image = null;
  
    try {
      if (uploadedFile) {
        base64Image = await convertToBase64(uploadedFile);
        
      }
  
      if (applyForAllPractices) {
        for (const site of practiceSites) {
          const response = await axios.get(
            `/site/${site.id}/get_site_consent_clause_data`
          );
          const formData = {
            logo: base64Image,
            display_name: response.data.display_name,
          };
          
          await mutation.mutateAsync({ id: site.id, formData });
        }
      }
  
      const formData = {
        logo: base64Image,
        display_name: practiceName,
      };
  
      
      await mutation.mutateAsync({ id: selectedSite, formData });
  
    } catch (error) {
      console.error("Error uploading data:", error);
      setUploadError("Error uploading data. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
  });

  return (
    <div>
      <div className={styles.modal}>
        <Box mb={4}>
          <h2 className={styles.header}>Upload Practice Name and Logo</h2>
        </Box>

        <h3 className={styles.header}>Select a Practice</h3>
        <Select
          value={selectedSite}
          onChange={handleChange}
          fullWidth
          variant="filled"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {practiceSites.map((site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>

        {!selectedSitecheck && (
          <>
            <Box mt={2} className={styles.content}>
              <h3 className={styles.header}>
                Existing Practice Display Name & Logo
              </h3>
              <div className={styles.logoAndName}>
                {practiceData.logo ? (
                  <img
                    src={practiceData.logo}
                    alt="Logo"
                    className={styles.logo}
                  />
                ) : (
                  <div className={styles.noLogoText}>No Logo</div>
                )}
                <h2>{practiceData.display_name}</h2>
              </div>
            </Box>

            <h3 className={styles.header}>
              Display Name for Selected Practice
            </h3>
            <TextField
              label="Practice Name"
              name="practiceName"
              required
              type="text"
              variant="filled"
              fullWidth
              className="mg-b-1"
              value={practiceName}
              onChange={(e) => setPracticeName(e.target.value)}
            />

            <h3 className={styles.header}>Logo Upload</h3>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {uploadedImageUrl ? (
                <img
                  src={uploadedImageUrl}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : (
                <>
                  <img
                    src="../../Upload.png"
                    alt="Drop Here"
                    className={styles.dropIcon}
                  />
                  <p className={styles.dropzoneText}>
                    Drag and drop an image here, or click to select one. (
                    <span className={styles.subt}>
                      logo will be resized to 50px By 50px
                    </span>
                    )
                  </p>
                </>
              )}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyForAllPractices}
                  onChange={(e) => setApplyForAllPractices(e.target.checked)}
                  color="primary"
                />
              }
              label="Apply Logo for all Practices"
            />
          </>
        )}

        {uploading && (
          <Box mt={2} textAlign="center">
            <CircularProgress />
            <Typography variant="body2">Uploading...</Typography>
          </Box>
        )}
        {uploadError && (
          <Box mt={2} textAlign="center">
            <Typography color="error" variant="body2">
              {uploadError}
            </Typography>
          </Box>
        )}

        <Box mt={1} textAlign="right">
          <Button
            className={styles.modelsubmitButton}
            type="button"
            variant="contained"
            onClick={handleSubmit}
            disabled={uploading}
          >
            Save
          </Button>
        </Box>
      </div>

      <Dialog
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.5)",
          },
        }}
      >
        <DialogTitle style={{ textAlign: "left", color: "WindowFrame" }}>
          Confirm Action{" "}
          <SdCardAlertIcon
            style={{ fontSize: 35, marginBottom: -8, color: "orange" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "WindowFrame",
              fontSize: "larger",
              fontWeight: "bold",
            }}
          >
            Are you sure you want to apply the logo to all practices?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setIsConfirmationOpen(false);
              await uploadData();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
