import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Box } from '@mui/material';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from 'js-cookie';
import CircularLoader from '../../components/CircularLoader';

/**
 * Renders a list of current users, filtered by dentist or practice, and optionally sorted by size.
 * Fetches user data from an API based on the provided location filter.
 * Displays user names and their compliance and patient engagement scores as horizontal bars.
 *
 * @param {Object} props The component props.
 * @param {string} props.filter Determines the type of users to display ('dentist' or 'practice').
 * @param {string} props.location Used to filter users by location in the API request.
 * @param {string} props.size Determines the order of the list ('ascend' for ascending).
 *
 * @returns {React.ReactElement} A list component populated with user data or a loading indicator.
 */
const CurrentUsersList = ({ filter, location, size }) => {
  const [dentist, setDentist] = useState([]);
  const [practice, setPractice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  let dynamicMaxHeight;
  if (isXs) dynamicMaxHeight = 500;
  else if (isMd) dynamicMaxHeight = 300;
  else if (isLg) dynamicMaxHeight = 200;

  useEffect(() => {
    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };

    const fetchPractices = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}admin/current_users/practices?location=${location}`;
        const practiceResponse = await axios.get(practiceURL, config);
        setPractice(practiceResponse.data);
      } catch (error) {
        console.error('Error fetching practices:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDentists = async () => {
      try {
        const dentistURL = `${process.env.REACT_APP_BASE_URL}admin/current_users/dentists?location=${location}`;
        const dentistResponse = await axios.get(dentistURL, config);
        setDentist(dentistResponse.data);
      } catch (error) {
        console.error('Error fetching dentists:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (filter === 'dentist') {
      fetchDentists();
    } else if (filter === 'practice') {
      fetchPractices();
    }
  }, [filter, location]);

  const getBarColor = (value) => {
    const hue = ((value / 100) * 120).toString(10);
    return `hsl(${hue}, 80%, 70%)`;
  };

  const getContent = () => {
    let listToShow = filter === 'dentist' ? [...dentist] : [...practice];

    if (size === 'ascend') {
      listToShow.reverse();
    }

    return listToShow.map((item, index) => {
      if (filter === 'dentist') {
        return (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ListItemAvatar sx={{ alignSelf: 'center' }}>
              <Avatar>{item.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.practice}
              sx={{ flex: 1, alignSelf: 'center', mr: 2 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, maxWidth: '50%' }}>
              <Box sx={{ backgroundColor: '#f7f7f7', width: '100%', height: '10px' }}>
                <Box sx={{
                  backgroundColor: getBarColor(item.compliance_management_score),
                  width: `${item.compliance_management_score}%`,
                  height: '10px',
                  maxWidth: '100%'
                }} />
              </Box>
              <Box sx={{ mt: '5px', backgroundColor: '#f7f7f7', width: '100%', height: '10px' }}>
                <Box sx={{
                  backgroundColor: getBarColor(item.patient_engagement_score),
                  width: `${item.patient_engagement_score}%`,
                  height: '10px',
                  maxWidth: '100%'
                }} />
              </Box>
            </Box>
          </ListItem>

        );
      } else {
        return (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ListItemAvatar sx={{ alignSelf: 'center' }}>
              <Avatar>{item.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              sx={{ flex: 1, alignSelf: 'center', mr: 2 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, maxWidth: '50%' }}>
              <Box sx={{ backgroundColor: '#f7f7f7', width: '100%', height: '10px' }}>
                <Box sx={{
                  backgroundColor: getBarColor(item.compliance_management_score),
                  width: `${item.compliance_management_score}%`,
                  height: '100%',
                  maxWidth: '100%'
                }} />
              </Box>
              <Box sx={{ mt: '5px', backgroundColor: '#f7f7f7', width: '100%', height: '10px' }}>
                <Box sx={{
                  backgroundColor: getBarColor(item.patient_engagement_score),
                  width: `${item.patient_engagement_score}%`,
                  height: '100%',
                  maxWidth: '100%'
                }} />
              </Box>
            </Box>
          </ListItem>

        );
      }
    });
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  };
  return (
    <List id="existing-users-CurrentUsersList" sx={isLoading ? containerStyle : { width: '100%', maxHeight: dynamicMaxHeight, overflow: 'auto' }}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        getContent()
      )}
    </List>
  );
}

export default CurrentUsersList;