import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../components/CircularLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

const pieChartOptions = {
    chart: {
        type: 'donut',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    labels: [],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(1) + '%';
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(0);
            }
        }
    },
    plotOptions: {
        pie: {
            expandOnClick: true
        }
    },
    legend: {
        position: 'bottom',
        fontSize: '13px',
        itemMargin: {
            horizontal: 10,
            vertical: 5
        }
    },
    colors: ['#94BBD4', '#E1E1E1', '#FFBD88']
};

/**
 * `VideoCompletionsChart` is a React component that visualizes video completion rates in a donut chart format.
 * It aims to provide insights into how videos related to patient care are being consumed, by fetching and
 * displaying data based on the selected practice, clinician, and location. The component updates its data
 * and rerenders the chart upon receiving new props or during initial mount.
 *
 * The chart leverages ReactApexChart with customized `pieChartOptions` for aesthetic and functional purposes,
 * including animation effects for a more dynamic user experience. Data fetching is handled via Axios with
 * secure authentication through Cookies.
 *
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 * @param {string} props.location - Selected location for filtering the chart data.
 */
class VideoCompletionsChart extends React.Component {
    /**
     * Initializes the component with a predefined state including the chart's series data,
     * chart configuration options, and a loading indicator.
     * @param {Object} props - The props passed to the component.
     * 
     * @state {Object} state - The internal state of the component.
     * @state {Array} state.series - The data series for the chart.
     * @state {Object} state.options - The chart configuration options.
     * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: pieChartOptions,
            isLoading: false
        };
    }

    /**
     * Fetches new data for the chart when the component updates due to changes in its props.
     * @param {Object} prevProps - Previous props for comparison to current props.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice || prevProps.clinician !== this.props.clinician || prevProps.location !== this.props.location) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches the data for the video completions chart based on the current props and updates
     * the component's state accordingly. Shows a loading indicator while data is being fetched
     * and handles any errors encountered during the fetch operation.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const url = `${process.env.REACT_APP_BASE_URL}admin/patient_video_completions?practice=${this.props.practice}&clinician=${this.props.clinician}&location=${this.props.location}`;

        const token = axios.defaults.headers.common.Authorization
        const config = {
            headers: {
                Authorization: `${token}`
            }
        };

        axios.get(url, config)
            .then(response => {
                const newSeries = response.data.map(item => item.value);
                const newLabels = response.data.map(item => item.label);

                const updatedOptions = {
                    ...this.state.options,
                    labels: newLabels
                };

                this.setState({ series: newSeries, options: updatedOptions, isLoading: false });
            })
            .catch(error => {
                console.error('Error fetching chart data:', error.message);
                this.setState({ isLoading: false });
            })
    }

    /**
     * Invokes the data fetching method after the component is mounted to ensure the chart is populated
     * with data as soon as it appears in the DOM.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the video completions chart using ReactApexChart. Displays a loading indicator while data is
     * being fetched and a message if no data matches the current filter criteria.
     * 
     * @returns {React.ReactElement} The rendered donut chart or loading/message indicator.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
        };
        const allValuesAreZero = this.state.series.every(value => value === 0);

        return (
            <div id="patient-engagement-VideoCompletionsChart" style={this.state.isLoading ? containerStyle : {}}>
                {this.state.isLoading ? (
                    <CircularLoader />
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={400} />
                )}
            </div>
        );
    }
}

export default VideoCompletionsChart;
