import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import styles from "./Summary.module.scss";
import cx from "classnames";
import { find } from "lodash";
import QRCode from "react-qr-code";
import { Modal } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { getPdfName } from "../utils/utils";
import { Box } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import GearIcon from "@mui/icons-material/Settings";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Summary() {
  let { consentBundleId } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const [value, setValue] = React.useState(1);
  const [isOpenQrCode, setIsOpenQrCode] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [tooltipData, setTooltipData] = React.useState(null);
  const [loadingTooltip, setLoadingTooltip] = React.useState(false);

  const summaryDetails = useQuery(
    ["summaryDetails", consentBundleId],
    async () => {
      const { data } = await axios.get(
        `/consent_bundle/${consentBundleId}/activity_summary`
      );
      return data;
    },
    {
      onSuccess: (data) => {
       
        if (data.status === "Draft") {
          navigate(`/${data.data.treatment_plan_id}`);
        }
      },
    }
  );

  const manuallySign = useMutation(
    (id) => {
      return axios.put(`/consent_bundle/${id}/mark_consent_bundle_as_signed`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["summaryDetails"] });
      },
    }
  );

  const getVideoStatus = (video) => {
    if (summaryDetails?.data?.playlist_activity) {
      const details = find(summaryDetails.data.playlist_activity, {
        video_id: video.id,
      });
      return details;
    }
    return {
      watch_percentage: 0,
      watch_status: "Not Watched",
    };
  };

  

  const downloadFullReport = () => {
    setIsDownloading(true);
    let url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/download_full_consent_bundle_report`;
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", getPdfName(summaryDetails.data));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDownloading(false);
    });
  };

  const createNewVersion = useMutation(
    (id) =>
      axios.post(`/consent_bundle/${id}/create_consent_bundle_new_version`),
    {
      onSuccess: (data) => {
       
        queryClient.invalidateQueries({ queryKey: ["summaryDetails"] });
        navigate(`/${data.data}`);
      },
    }
  );

  const handleCreateNewVersion = () => {
    confirmAlert({
      title: "Confirm",
      message:
        "Are you sure you want to create a new version of this consent bundle?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            createNewVersion.mutate(consentBundleId);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const PreviousVersion = async () => {
    setIsDownloading(true);
    try {
      const response = await axios.get(
        `consent_bundle/${consentBundleId}/get_consent_bundle_versions`
      );
      console.log(response.data);
      const fetchedData = response.data;
      const latestVersion =
        fetchedData && Array.isArray(fetchedData) && fetchedData.length > 0
          ? Math.max(...fetchedData.map((row) => row.version))
          : null;
      const updatedData = fetchedData.map((row) => ({
        ...row,
        isLatest: row.version === latestVersion,
      }));

      setData(updatedData);
      setOpen(true);
      

    } catch (error) {
      console.error("Error fetching previous versions:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  //maula downlaod 
  const download = () => {
    setIsDownloading(true);
    let url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_offline_pdf`;
    if (summaryDetails?.data?.status === "Completed") {
      url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_pdf`;
    }
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", getPdfName(summaryDetails.data));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDownloading(false);
    });
  };


//previous downlaod 
  const handleGoTo = async (consentBundleId) => {
    setIsDownloading(true);
    let url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_pdf`;
    if (summaryDetails?.data?.status === "Completed") {
      url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_pdf`;
    }
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", getPdfName(summaryDetails.data));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDownloading(false);
    });
    handleClose();
    try {
    } catch (error) {
      console.error("Error fetching activity summary:", error);
    }
  };

  const handleTooltipOpen = async () => {
    setLoadingTooltip(true);
    try {
      const response = await axios.get(
        `consent_bundle/${consentBundleId}/get_consent_bundle_versions`
      );

      setTooltipData(response.data);
    } catch (error) {
      console.error("Error fetching tooltip data:", error);
    } finally {
      setLoadingTooltip(false);
    }
  };

  const getTooltipContent = () => {
    if (loadingTooltip) {
      return <div>Loading...</div>;
    } else if (tooltipData && tooltipData.length > 0) {
      return (
        <ul style={{ padding: 0, margin: 0, textAlign: "center" }}>
          {tooltipData.map((item) => (
            <li key={item.consent_bundle_id} style={{ margin: "4px 0" }}>
              <span style={{ fontSize: "16px" }}>
                ID: {item.consent_bundle_id}
              </span>
              <span style={{ marginLeft: "8px", fontSize: "16px" }}>
                Version: {item.version}
              </span>
            </li>
          ))}
        </ul>
      );
    }
    return <div>No data available</div>;
  };

  return (
    <div className={styles.summary}>
      <div className={styles.activitySummary}>
        <h2 className={styles.header}>Consent Activity Summary</h2>
        {summaryDetails?.data?.patient && (
          <div className={styles.column}>
            <p className={styles.dataItem}>
              Name: {summaryDetails.data.patient.title}{" "}
              {summaryDetails.data.patient.first_name}{" "}
              {summaryDetails.data.patient.last_name}
            </p>
            <p className={styles.dataItem}>
              Email: {summaryDetails.data.patient.email_address}
            </p>
            <p className={styles.dataItem}>
              Mobile: {summaryDetails.data.patient.mobile_phone}
            </p>
            <p className={styles.dataItem}>
              Phone: {summaryDetails.data.patient.home_phone}
            </p>
            <p className={styles.dataItem}>
              Address: {summaryDetails.data.patient.address_line_1}{" "}
              {summaryDetails.data.patient.address_line_2}
            </p>
            <p className={styles.dataItem}>
              NHS Number: {summaryDetails.data.patient.nhs_number}
            </p>
            <p className={styles.dataItem}>
              Created on:{" "}
              {new Date(summaryDetails.data.created_at).toDateString()}
            </p>
            <p className={styles.dataItem}>
              Treatment Plans:{" "}
              {summaryDetails?.data?.treatment_plan?.treatments
                ?.map((t) => t.description)
                ?.join(", ")}
            </p>
            <p className={styles.dataItem}>
              Version: {summaryDetails.data.version_no}
            </p>
          </div>
        )}
        {/* {summaryDetails?.data?.is_old_version && (
          <Button
            variant="contained"
            color="secondary"
            onClick={PreviousVersion}
            disabled={isDownloading}
          >
            View Versions
          </Button>
        )} */}
        {summaryDetails?.data?.version_no > 1 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={PreviousVersion}
            disabled={isDownloading}
          >
            View Versions
          </Button>
        )}
        &nbsp;
        {!summaryDetails?.data?.is_old_version && (
          <Button
            variant="contained"
            color="info"
            onClick={handleCreateNewVersion}
            disabled={createNewVersion.isLoading}
            startIcon={<GearIcon />}
          >
            Edit Consent Bundle as New Version
          </Button>
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Versions</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Consent Bundle ID</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && Array.isArray(data) && data.length > 0 ? (
                    data.map((row) => (
                      <TableRow key={row.consent_bundle_id}>
                        <TableCell>{row.consent_bundle_id}</TableCell>
                        <TableCell>{row.code}</TableCell>
                        <TableCell>{row.version}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: row.isLatest
                                ? "green"
                                : "Crimson",
                            }}
                            onClick={() => handleGoTo(row.consent_bundle_id)}
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        No previous versions found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={styles.stats}>
        <div className={styles.video}>
          <h2 className={styles.header}>Video Status</h2>
          {summaryDetails?.data?.playlists?.length > 0 ? (
            <TabContext value={value}>
              <TabList
                onChange={(e, v) => setValue(v)}
                variant="scrollable"
                className={styles.tabList}
              >
                {summaryDetails?.data?.playlists?.map((list, index) => {
                  if (list.status) {
                    return <Tab label={list?.title} value={index + 1} />;
                  }
                  return null;
                })}
              </TabList>
              {summaryDetails?.data?.playlists?.map(
                (playlist, playlistsIndex) => {
                  if (playlist.status) {
                    return (
                      <TabPanel
                        className={styles.playList}
                        value={playlistsIndex + 1}
                      >
                        {playlist?.playlist_items?.map((video) => {
                          if (video.status) {
                            return (
                              <div className={styles.videoItem}>
                                <img
                                  className={styles.thumbnail}
                                  src={video.thumbnail}
                                />
                                <div className={styles.videoDetails}>
                                  <p>{video.title}</p>
                                  <p
                                    className={cx(styles.pending, {
                                      [styles.success]:
                                        getVideoStatus(video).watch_percentage >
                                        0.8,
                                      [styles.error]:
                                        getVideoStatus(video)
                                          .watch_percentage === 0,
                                    })}
                                  >
                                    {getVideoStatus(video).watch_status} (
                                    {getVideoStatus(video).watch_percentage >= 1
                                      ? "100"
                                      : parseFloat(
                                          getVideoStatus(video)
                                            .watch_percentage * 100
                                        ).toFixed(2)}
                                    %)
                                  </p>
                                  <p>
                                    Last Accessed:{" "}
                                    {getVideoStatus(video).last_watched || "-"}
                                  </p>
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </TabPanel>
                    );
                  }
                  return null;
                }
              )}
            </TabContext>
          ) : (
            <p className={styles.noVideos}>
              No video logs to display in the summary page
            </p>
          )}
        </div>
        <div className={styles.consent}>
          <h2 className={styles.header}>Consent Status</h2>
          <p
            className={cx({
              [styles.success]: summaryDetails?.data?.status,
              [styles.pending]: !summaryDetails?.data?.status,
            })}
          >
            {summaryDetails?.data?.status}
          </p>
          {summaryDetails?.data?.status !== "Completed" ? (
            <>
              {!summaryDetails?.data?.patient?.email_address && (
                <p className={styles.warning}>
                  We can't find an email related to{" "}
                  {summaryDetails?.data?.patient?.title}{" "}
                  {summaryDetails?.data?.patient?.first_name}{" "}
                  {summaryDetails?.data?.patient?.last_name}. Please share this
                  link with your patient
                </p>
              )}
              <div className={styles.signDigitally}>
                <h3>Sign Remotely</h3>
                <p>
                  We have shared this link with your patient to give them access
                  to the consent documents.
                  <br />
                  <b>
                    Copy this link if you would like to resend it manually via
                    email or text messaging.
                  </b>
                </p>
                <div className={styles.buttonGroup}>
                  <p>
                    {summaryDetails?.data?.link ||
                      `${process.env.REACT_APP_PATIENT_URL}/${consentBundleId}`}
                  </p>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        summaryDetails?.data?.link ||
                          `${process.env.REACT_APP_PATIENT_URL}/${consentBundleId}`
                      );
                    }}
                  >
                    Copy link
                  </Button>
                </div>
              </div>
              <div className={styles.signManually}>
                <h3>Sign In the waiting room</h3>
                <div className={styles.column}>
                  <div className={cx(styles.columnSection, styles.borderRight)}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setIsOpenQrCode(true);
                      }}
                    >
                      Generate QR code
                    </Button>
                  </div>
                  <div className={styles.columnSection}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={download}
                      disabled={isDownloading}
                    >
                      Print consent
                    </Button>
                    <p>
                      If Patient signed manually please click "Signed Manually"
                      to finalize the consent
                    </p>
                    <Button
                      variant="contained"
                      className={styles.signedManuallyButton}
                      size="large"
                      onClick={() => {
                        confirmAlert({
                          title: "Confirm",
                          message:
                            "I declare that I will present this consent form to the patient to sign manually",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: async () => {
                                manuallySign.mutate(summaryDetails?.data.id);
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {},
                            },
                          ],
                        });
                      }}
                      disabled={manuallySign.isLoading}
                    >
                      Signed Manually
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {summaryDetails?.data?.patient_signature?.signed_type ===
              "manual" ? (
                <p>Signed manually at the practice</p>
              ) : (
                <p>
                  Signed on:{" "}
                  {summaryDetails?.data?.patient_signature?.signed_timestamp
                    ? new Date(
                        summaryDetails?.data?.patient_signature?.signed_timestamp
                      ).toDateString()
                    : "Pending"}
                </p>
              )}
              <Button
                variant="contained"
                size="large"
                onClick={download}
                disabled={isDownloading}
              >
                Download consent
              </Button>

              <Box mt={2}>
                {summaryDetails?.data?.status === "Completed" &&
                  summaryDetails?.data?.version_no > 1 && (
                    <Tooltip
                      title={getTooltipContent()}
                      onOpen={handleTooltipOpen}
                      arrow
                    >
                      <Button
                        variant="contained"
                        size="large"
                        onClick={downloadFullReport}
                        disabled={isDownloading}
                        style={{
                          backgroundColor: "red",
                          color: "white",
                        }}
                      >
                        Download the full consent report
                      </Button>
                    </Tooltip>
                  )}
              </Box>
            </>
          )}
        </div>
      </div>
      <Modal open={isOpenQrCode} onClose={() => setIsOpenQrCode(false)}>
        <div className={cx("modal", styles.modal)}>
          <QRCode
            value={
              summaryDetails?.data?.link ||
              `${process.env.REACT_APP_PATIENT_URL}/${consentBundleId}`
            }
          />
        </div>
      </Modal>
    </div>
  );
}
