import * as React from 'react';
import { Modal } from '@mui/material';
import cx from 'classnames';
import styles from './ConsentLogs.module.scss';
import { useQuery } from '@tanstack/react-query'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

function ConsentLogs({ isOpen, closeModal, consentId }) {
  const consentLogs = useQuery(
    ['consentLogs', consentId],
    async () => {
      const { data } = await axios.get(`/consent_bundle/${consentId}/interaction_log`);
      return data;
    }, {
      enabled: !!consentId
    }
  );

  return (
      <Modal
        open={isOpen}
        onClose={() => closeModal()}
      >
        <div className={cx("modal", styles.container)}>
            <CloseIcon className={styles.close} onClick={() => closeModal()} />
            {consentLogs.isLoading && <div className="loading">Loading...</div>}
            {consentLogs.isSuccess && (
              <table className={styles.logs}>
                <tr>
                  <th>Date Accessed</th>
                  <th>Interaction log</th>
                </tr>
                {Object.keys(consentLogs.data).map((date) => (
                  <tr>
                    <td><h4>{date}</h4></td>
                    <td>
                      {consentLogs.data[date].map((log, index) => {
                        return (
                          <>
                            <h4>{log.event}</h4>
                            {log.description.length > 0 && (
                              <ul>
                                {log.description.map((description) => (
                                  <li>{description}</li>
                                ))
                                }
                              </ul>
                            )}
                          </>
                        )
                      })}
                    </td>
                  </tr>
                ))}
              </table>
            )}
        </div>
      </Modal> 
  );
}
export default ConsentLogs;