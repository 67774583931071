import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import MainCard from '../../components/MainCard';
import Sidebar from "../../menu-items/Sidebar";

/**
 * Displays a help and support page containing FAQs and contact information. The FAQs cover various
 * aspects of the service such as time savings calculation, compliance management score, patient engagement
 * score, and more. The contact section provides a phone number, email address, and physical address for support.
 * 
 * This page uses Material UI components for layout and styling, including Grid for layout structure, Typography
 * for text display, and MainCard for containing sections of content.
 * 
 * No props are required for this component.
 * 
 * @returns {React.ReactElement} A React functional component representing the help and support page.
 */
const HelpPage = () => (
  <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
    <Grid container rowSpacing={2.5} columnSpacing={2.75}>
      {/* Sidebar */}
      <Grid item xs={12} md={3} lg={2}>
        <Sidebar />
      </Grid>

      {/* FAQ and Contact sections */}
      <Grid item xs={12} md={9} lg={10}>
        <Grid container spacing={2.75}>
          {/* FAQs Section */}
          <Grid item xs={12}>
            <MainCard content={false}>
              <Box sx={{ pl: 8, pt: 5, pb: 0, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  FAQs
                </Typography>
              </Box>
              <Box sx={{ pl: 8, pr: 8, pt: 3, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How is my time saved calculated?
                </Typography>
                <Typography variant="h6">
                  Your time saved figure uses an estimation of how long a user spends on consent management with traditional methods. For every consent completed by a user, it this is compared to estimated time using the Medicube system (At practice, Online, or QR code).
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How are my potential time savings calculated?
                </Typography>
                <Typography variant="h6">
                  Your potential time savings figure is based on an estimated average time savings per user using the Medicube system. This is multiplied by the number of unsubscribed users at your practices.
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How is my compliance management score calculated?
                </Typography>
                <Typography variant="h6">
                  Your compliance management score is based on how your consents are being signed by your patients. A final score is reached based on the percentage in each category (from best to worst: Online, QR code, At practice, Manual). A higher score means more time savings!
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How is my patient engagement score calculated?
                </Typography>
                <Typography variant="h6">
                  Your patient engagement score is calculated using the average watch time for all playlist items in consent bundles you have sent out. A higher score means your patients engaging more with your videos!
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How are the leaderboard overall score calculated?
                </Typography>
                <Typography variant="h6">
                  Your overall score is a simple average of the compliance and patient engagement scores.
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 1.5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How are scores/charts calculated if the filters cover more than one user?
                </Typography>
                <Typography variant="h6">
                  An average value is given if the filters include multiple users.
                </Typography>
              </Box>

              <Box sx={{ pl: 8, pr: 8, pt: 0, pb: 5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  How is the retention rates chart calculated?
                </Typography>
                <Typography variant="h6">
                  The retention rates chart finds all subscriptions that are active at a given start date. It then tracks when any subscriptions end. If no filters are provided, it covers the last year.
                </Typography>
              </Box>
            </MainCard>
          </Grid>

          {/* Contact Section */}
          <Grid item xs={12}>
            <MainCard content={false}>
              <Box sx={{ pl: 8, pt: 5, pb: 0, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                  Contact us
                </Typography>
              </Box>
              <Box sx={{ pl: 8, pt: 3, pb: 5, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                  <strong>Phone number:</strong> 1234567890{'     '}<strong>Email:</strong> example@example.com
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                  <strong>Contact address:</strong> Flat, Street, City, Country, Post Code
                </Typography>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default HelpPage;
