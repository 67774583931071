import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import MainCard from "../../components/MainCard";
import ScoreBarChart from './ScoreBarChart';
import VideoCompletionsChart from './VideoCompletionsChart';
import HighestVideoTable from './VideoTable';
import FeedbackTable from './FeedbackTable';
import Loader from '../../components/Loader';
import PDFExportComponent from "../../components/PDFExportComponent";
import Sidebar from "../../menu-items/Sidebar";

const PatientPage = () => {
  const [practiceList, setPracticeList] = useState([{ label: "All practices", value: "all" }]);
  const [practice, setPractice] = useState('all');
  const [clinicianList, setClinicianList] = useState([{ label: "All clinicians", value: "all" }]);
  const [clinician, setClinician] = useState('all');
  const [locationList, setLocationList] = useState([{ label: "All locations", value: "all" }]);
  const [location, setLocation] = useState('all');

  const filters = {
    location: location,
    practice: practice,
    clinician: clinician
  };
  const chartIds = [
    { id: 'maincard_patient_video_completions', singleRow: true, scale: 1.1 },
    { id: 'box_patient_feedback', singleRow: true, scale: 0.5 },
    { id: 'patient-engagement-FeedbackTable', singleRow: true, ignoreMaxHeight: true, scale: 1 },
    { id: 'box_average_video_engagement', singleRow: true, scale: 0.8 },
    { id: 'patient-engagement-VideoTable', singleRow: true, ignoreMaxHeight: true, scale: 1 },
  ];
  const fileName = 'report-patient-engagement.pdf';

  useEffect(() => {
    let isMounted = true;
    const fetchLocationList = async () => {
      try {
        const locationURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/locations`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            Authorization: `${token}`
          }
        };
        const locationResponse = await axios.get(locationURL, config);
        if (isMounted) {
          if (locationResponse.data && locationResponse.data.length > 0) {
            const updatedLocationList = [
              { label: "All locations", value: "all" },
              ...locationResponse.data
            ];
            setLocationList(updatedLocationList);
            setLocation(updatedLocationList[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching location data: ', error);
      }
    };

    fetchLocationList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        if (location) {
          const practiceURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/practices?location=${location}`;
          const token = axios.defaults.headers.common.Authorization
          const config = {
            headers: {
              Authorization: `${token}`
            }
          };

          const practiceResponse = await axios.get(practiceURL, config);
          if (isMounted) {
            if (practiceResponse.data && practiceResponse.data.length > 0) {
              const updatedPracticeList = [
                { label: "All practices", value: "all" },
                ...practiceResponse.data
              ]
              setPracticeList(updatedPracticeList);
              setPractice(updatedPracticeList[0].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    const fetchClinicianList = async () => {
      try {
        if (practice) {
          const clinicianURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/clinicians?practice=${practice}&location=${location}`;
          const token = axios.defaults.headers.common.Authorization
          const config = {
            headers: {
              Authorization: `${token}`
            }
          };

          const clinicianResponse = await axios.get(clinicianURL, config);
          if (isMounted) {
            if (clinicianResponse.data && clinicianResponse.data.length > 0) {
              const updatedClinicianList = [
                { label: "All clinicians", value: "all" },
                ...clinicianResponse.data
              ]
              setClinicianList(updatedClinicianList);
              setClinician(updatedClinicianList[0].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching clinician data: ', error);
      }
    };

    fetchClinicianList();
    return () => {
      isMounted = false;
    };
  }, [location, practice]);

  if (locationList.length <= 1 || practiceList.length <= 1 || clinicianList.length <= 1) {
    return <Loader />
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Sidebar */}
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        {/* Filters and Score Chart */}
        <Grid item xs={12} md={9} lg={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MainCard>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    Patient Engagement
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="location-filter"
                      label="Location"
                      size="small"
                      select
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      fullWidth
                    >
                      {locationList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="practice-filter"
                      label="Practice"
                      size="small"
                      select
                      value={practice}
                      onChange={(e) => setPractice(e.target.value)}
                      fullWidth
                    >
                      {practiceList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box>
                    <TextField
                      id="clinician-filter"
                      label="Clinician"
                      size="small"
                      select
                      value={clinician}
                      onChange={(e) => setClinician(e.target.value)}
                      fullWidth
                    >
                      {clinicianList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={8}>
              <MainCard>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Patient Engagement Score
                </Typography>
                <ScoreBarChart
                  practice={practice}
                  clinician={clinician}
                  location={location}
                />
              </MainCard>
            </Grid>

            {/* Video Completions Chart */}
            <Grid item xs={12} md={4}>
              <MainCard id='maincard_patient_video_completions'>
                <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                  Patient Video Completions
                </Typography>
                <VideoCompletionsChart
                  practice={practice}
                  clinician={clinician}
                  location={location}
                />
              </MainCard>
            </Grid>

            {/* Average Video Engagement */}
            <Grid item xs={12} md={4}>
              <MainCard>
                <Typography id='box_average_video_engagement' variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                  Average Video Engagement
                </Typography>
                <HighestVideoTable
                  practice={practice}
                  clinician={clinician}
                  location={location}
                />
              </MainCard>
            </Grid>

            {/* Patient Feedback */}
            <Grid item xs={12} md={4}>
              <MainCard>
                <Typography id='box_patient_feedback' variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                  Patient Feedback
                </Typography>
                <FeedbackTable
                  practice={practice}
                  clinician={clinician}
                  location={location}
                />
              </MainCard>
              <Box sx={{ mt: 2, textAlign: 'right' }}>
                <PDFExportComponent
                  filters={filters}
                  chartIds={chartIds}
                  fileName={fileName}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

export default PatientPage;
