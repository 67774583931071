import React from 'react';
import CircularLoader from '../../componenets/CircularLoader';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Cookies from "js-cookie";
const barChartOptions = {
    chart: {
        type: 'bar',
        height: 125,
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
            columnWidth: '45%',
            borderRadius: 4,
            dataLabels: {
                position: 'top'
            },
            distributed: true
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(1) + "%";
        },
        style: {
            fontSize: '12px',
            colors: ["#444444"]
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(1);
            }
        }
    },
    xaxis: {
        categories: ['Patient engagement'],
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
    },
    yaxis: {
        max: 100,
        show: false
    },
    annotations: {
        xaxis: [
            {
                x: 50,
                borderColor: '#999999',
                strokeDashArray: 5
            },
            {
                x: 70,
                borderColor: '#999999',
                strokeDashArray: 5
            },
            {
                x: 80,
                borderColor: '#999999',
                strokeDashArray: 5
            },
            {
                x: 90,
                borderColor: '#999999',
                strokeDashArray: 5
            }
        ]
    },
    grid: {
        show: false
    },
    legend: {
        show: false
    }
};

/**
 * `ScoreBarChart` displays a horizontal bar chart representing the patient engagement score for a selected practice
 * and clinician. It fetches and visualizes the engagement score upon component initialization and whenever the 
 * relevant props change. The component displays a loading indicator while fetching data and handles error states gracefully.
 *
 * The chart is rendered using ReactApexChart with customized options defined in `barChartOptions`, including
 * a unique color scheme that adjusts based on the score value, and annotations for significant thresholds.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 */
class ScoreBarChart extends React.Component {
    /**
     * Constructs the ScoreBarChart component with initial state.
     * @param {Object} props - The props passed to the component.
     * 
     * @state {Object} state - The internal state of the component.
     * @state {Array} state.series - The data series for the chart.
     * @state {Object} state.options - The chart configuration options.
     * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: barChartOptions,
            isLoading: false
        };
    }

    /**
     * Called after the component updates. Fetches new chart data if the relevant props have changed.
     * @param {Object} prevProps - Previous props for comparison.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice ||
            prevProps.clinician !== this.props.clinician) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches chart data from a specified API endpoint using Axios, updating the component's
     * state with the new data for rendering the chart, or handles errors if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const { practice, clinician } = this.props;
        const url = `${process.env.REACT_APP_BASE_URL}users/patient_engagement_score?practice=${practice}&clinician=${clinician}`;

        const token = axios.defaults.headers.common.Authorization
        const config = {
            headers: {
                "Authorization": `${token}`
            }
        };

        axios.get(url, config)
            .then(response => {
                if (response.data && typeof response.data[0].value === 'number') {
                    const dataValue = response.data[0].value;
                    const updatedSeries = [{
                        name: 'Score',
                        data: [{ x: 'Patient engagement', y: dataValue }]
                    }];

                    this.setState({
                        series: updatedSeries,
                        options: {
                            ...this.state.options,
                            colors: updatedSeries[0].data.map(d => this.getBarColor(d.y))
                        },
                        isLoading: false
                    });
                } else {
                    console.error('Invalid or missing data:', response);
                    this.setState({ isLoading: false, series: [] });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ isLoading: false, series: [] });
            });
    }

    /**
     * Determines the color of the bar based on the score value, using a HSL color scheme.
     * @param {number} value - The score value.
     * @returns {string} - The HSL color string for the bar.
     */
    getBarColor(value) {
        const hue = ((value / 100) * 120).toString(10);
        return `hsl(${hue}, 80%, 70%)`;
    }

    /**
     * React lifecycle method, called immediately after the component is mounted,
     * triggering the initial data fetch for the chart.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the bar chart or a loading indicator based on the current state.
     * @returns {React.ReactElement} - The rendered component.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            marginLeft: '20px',
            marginRight: '20px'
        };
        return (
            <div id="chart" style={this.state.isLoading ? containerStyle : { marginLeft: '20px', marginRight: '20px' }}>
                {this.state.isLoading ? (
                    <CircularLoader />
                ) : (
                    <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={100} />
                )}
            </div>
        );
    }
}


export default ScoreBarChart;
