import * as React from 'react';
import { Form } from 'react-final-form'
import * as Yup from 'yup';
import {TextField, makeValidate} from 'mui-rff';
import { useMutation } from '@tanstack/react-query'
import axios from 'axios';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';

import styles from './Corporate.module.scss';


//validation
const schema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    no_of_dental_practices: Yup.string().required('Number of dental practices is required'),
    no_of_practitioners: Yup.string().required('Number of practitioners is required'),
    email: Yup.string().required('Email is required'),
    contact_no: Yup.string().required('Contact number is required'),
});

const validate = makeValidate(schema);

function CorporateForm() {

    const contactSale = useMutation(
        (data: any) => {
            return axios.post(`/subscription_package/corporate_inquiry`, data);
        },
        {
          onSuccess: () => {
            toast.success("Message sent successfully!")
          },
          onError: () => {
            toast.error("Something went wrong please try again")
          }
        }
    );

    return (
        <div className={styles.corporateForm}>
            <h1 className={styles.title}>Corporate</h1>
            <Box className={styles.cardLayout}>
                <Form
                    onSubmit={data => contactSale.mutateAsync(data)}
                    validate={validate}
                    render={({ handleSubmit }) => (
                    <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
                            <Box display="flex" flexDirection="row">
                                <TextField
                                    label="First Name"
                                    name="first_name"
                                    required={true}
                                    variant="outlined"
                                    style={{ marginRight: "10px" }} 
                                />

                                <TextField
                                    label="Last Name"
                                    name="last_name"
                                    required={true}
                                    variant="outlined"
                                />
                            </Box>
                            <TextField
                                label="No of Dental Practices"
                                name="no_of_dental_practices"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Estimated No of Practitioners"
                                name="no_of_practitioners"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Contact No"
                                name="contact_no"
                                required={true}
                                variant="outlined"
                            />
                            <LoadingButton
                                className={styles.submitButton} 
                                type="submit" variant="contained" 
                                disabled={contactSale.isLoading}
                                loading={contactSale.isLoading}
                            >Send</LoadingButton>
                        
                    </form>
                    )}
                />
            </Box>
        </div>  
    );
}
export default CorporateForm;