import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import styles from "./PrivacyPolicyModal.module.scss";

const EulaModal = ({ open, onClose, content }) => (
  <Modal open={open} onClose={onClose} className={styles.modal}>
    <Box className={styles.modalContent}>
      <Typography variant="h6" gutterBottom>
       Eula
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button onClick={onClose} variant="contained" color="primary">
        Close
      </Button>
    </Box>
  </Modal>
);

export default EulaModal;
