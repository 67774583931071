import React from 'react';
import { ScheduleOutlined } from '@ant-design/icons';
import './TimeCouldSave.css';
import CircularLoader from '../../components/CircularLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * `TimeCouldSave` is a React component that calculates and displays the potential time savings
 * if all users in a selected practice and location were to use the service within a specific date range.
 * It fetches this data from an API upon component mount or when the props change, and displays a loading
 * indicator while fetching. The component gracefully handles errors and displays the fetched time savings.
 *
 * Utilizes Ant Design icons for decorative purposes and custom CSS for styling.
 *
 * @param {Object} props - Component props.
 * @param {string} props.practice - The practice identifier for filtering the data.
 * @param {string} props.location - The location identifier for filtering the data.
 * @param {string} props.startDate - The start date of the date range filter.
 * @param {string} props.endDate - The end date of the date range filter.
 */
class TimeCouldSave extends React.Component {
  /**
   * Constructs the TimeCouldSave component with default state.
   * @param {Object} props - The props passed to the component.
   * 
   * @state {Object} state - The internal state of the component.
   * @state {number|null} state.timeSaved - The calculated time that could be saved, initially null.
   * @state {boolean} state.isLoading - Indicates whether the data is currently being fetched.
   */
  constructor(props) {
    super(props);
    this.state = {
      timeSaved: null,
      isLoading: false,
    };
  }

  /**
   * React lifecycle method called immediately after the component is mounted.
   * It triggers the initial data fetch.
   */
  componentDidMount() {
    this.fetchData();
  }

  /**
   * Called after the component updates to fetch new data if the practice, location,
   * startDate, or endDate props have changed.
   * @param {Object} prevProps - Previous props for comparison.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.practice !== this.props.practice ||
      prevProps.location !== this.props.location ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate) {
      this.fetchData();
    }
  }

  /**
   * Fetches the potential time savings data from the specified API endpoint. Updates the component's
   * state with the new data or sets an error state if the fetch fails.
   */
  fetchData() {
    this.setState({ isLoading: true });

    const url = `${process.env.REACT_APP_BASE_URL}admin/you_could_save?practice=${this.props.practice}&location=${this.props.location}&startDate=${this.props.startDate}&endDate=${this.props.endDate}`;
    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };
   
    axios.get(url, config)
      .then(response => {
        this.setState({ timeSaved: response.data.value, isLoading: false });
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
        this.setState({ isLoading: false, timeSaved: 0 });
      });

  }

  /**
   * Renders the component displaying the potential time savings or a loading indicator
   * while the data is being fetched.
   * 
   * @returns {React.ReactElement} The rendered component.
   */
  render() {
    const { timeSaved, isLoading } = this.state;

    return (
      <div className="could-container could-fixed-size-card">
        <ScheduleOutlined className="could-background-icon could-left-icon" />
        <div className="could-content">
          <div className="could-header">We could save potential users</div>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <div className="could-count">{timeSaved !== null ? timeSaved : 'Loading...'} hours</div>
          )}
          <div className="could-unit">if all were subscribed!</div>
        </div>
        <ScheduleOutlined className="could-background-icon could-right-icon" />
      </div>
    );
  }
}

export default TimeCouldSave;
