import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularLoader from '../../components/CircularLoader';
import Cookies from 'js-cookie';

/**
 * `FeedbackTable` displays a sortable table of feedback scores for a selected practice, clinician, and location.
 * The component fetches feedback data on initialization and whenever the props change, displaying a loading
 * indicator while fetching. The table can be sorted by score in ascending or descending order, with a rotate
 * animation applied to the sort icon to indicate the current sort direction.
 *
 * Utilizes Material UI components for the table display and an icon button for sorting action. 
 * Axios is used for fetching data, with authentication handled via Cookies.
 *
 * @param {Object} props - Component props.
 * @param {string} props.practice - Identifier for the selected practice to filter feedback data.
 * @param {string} props.clinician - Identifier for the selected clinician to filter feedback data.
 * @param {string} props.location - Identifier for the selected location to filter feedback data.
 * 
 * @returns {React.ReactElement} A table component populated with feedback data or a loading indicator/message based on the current state.
 */
function FeedbackTable({ practice, clinician, location }) {
  const [iconRotation, setIconRotation] = useState(0);
  const rotateStyle = {
    transform: `rotate(${iconRotation}deg)`,
    transition: 'transform 0.3s ease-in-out',
  };
  const sortRows = () => {
    const sortedRows = [...rows].sort((a, b) => sortAsc ? a.score - b.score : b.score - a.score);
    setRows(sortedRows);
    setSortAsc(!sortAsc);
    setIconRotation(iconRotation === 0 ? 180 : 0);
  };

  const [rows, setRows] = useState([]);
  const [sortAsc, setSortAsc] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_BASE_URL}admin/patient_feedback?practice=${practice}&clinician=${clinician}&location=${location}`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            Authorization: `${token}`
          }
        };
        const response = await axios.get(url, config);
        if (response.data && Array.isArray(response.data)) {
          const newRows = response.data.map(item => ({
            question: item.label,
            score: parseFloat(item.value).toFixed(1)
          }));
          setRows(newRows);
        } else {
          setRows(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setRows(null);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [practice, clinician, location]);


  if (isLoading) {
    return (
      <div data-testid="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
        <CircularLoader />
      </div>
    );
  }

  if (rows === null || rows.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
        No data matches these filters.
      </div>
    );
  }

  return (
    <div id="patient-engagement-FeedbackTable" style={{ width: '100%' }}>
      <TableContainer data-testid="feedback-table" sx={{ maxHeight: '330px', overflow: 'auto', width: '100%' }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center"><Typography fontWeight="bold">Category</Typography></TableCell>
              <TableCell align="center">
                <Typography fontWeight="bold" display="inline">Score</Typography>
                <IconButton aria-label="sort" onClick={sortRows} size="small">
                  <ArrowDownwardIcon fontSize="inherit" style={rotateStyle} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'grey.100' } }}>
                <TableCell component="th" scope="row" align="center">
                  {row.question}
                </TableCell>
                <TableCell align="center">
                  {row.score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FeedbackTable;
