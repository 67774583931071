import * as React from "react";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { TextField, makeValidate } from "mui-rff";
import styles from "./CreateConsentForm.module.scss";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const schema = Yup.object().shape({
  id: Yup.string().required(),
});

const validate = makeValidate(schema);

function CreateConsentForm({ isOpen, setIsOpen, user }) {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const [bannerMessage, setBannerMessage] = useState("");

  const createConsent = useMutation(
    (id) => axios.post(`/consent_bundle/submit_treatment_plan_for_consent_bundle_creation`, { id }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["patients"] });
        if (data.data.status === "Draft") {
          navigate(`/${data.data.id}`);
        } else {
          navigate(`/${data.data.id}/summary`);
        }
      },
      onError: (error) => {
        console.log("Error response status:", error?.response?.status);
        console.log("User type:", user);
        console.log("Error details:", error?.response?.data?.detail);

        if (error?.response?.status === 400) {
          let message = "";

          if (user === 1) {
            message = error?.response?.data?.detail === "User Not Under Subscription"
              ? "Your subscription plan is inactive. Please contact your administrator to purchase a new plan. <a href='/plans'> Go to Plans Page</a>"
              : "Your subscription plan is expired. Please visit the <a href='/plans'>Plans Page</a> to purchase a new plan.";
          } else if (user === 2) {
            message = error?.response?.data?.detail === "User Not Under Subscription"
              ? "User Not Under Subscription. Please contact your administrator or visit the <a href='/plans'>Plans Page</a> to purchase a new plan as an Associate Dentist."
              : "Your practice subscription plan is expired. Please visit the <a href='/plans'>Plans Page</a> to purchase a new plan as an Associate Dentist.";
          } else if (user === 3 || user === 4) {
            message = error?.response?.data?.detail === "expired"
              ? "Your practice subscription plan is expired. Please visit the <a href='/my-plan'>My Plans Page</a> to renew the subscription."
              : "User Not Under Subscription. Please contact your administrator or visit the <a href='/my-plan'>My Plans Page</a> to renew the subscription.";
          }

          setBannerMessage(message);
        } else {
          console.error(error);
        }
      }
    }
  );

  const sendHelpData = (data) => {
    createConsent.mutate(data.id);
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="modal">
        {bannerMessage && (
          <div
            className={styles.banner}
            dangerouslySetInnerHTML={{ __html: bannerMessage }}
          />
        )}
        <Form
          onSubmit={sendHelpData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form
              className={styles.headerForm}
              onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                label="Treatment Plan Id"
                name="id"
                required
                variant="outlined"
              />
              <Button
                className={styles.submitButton}
                type="submit"
                variant="contained"
                disabled={createConsent.isLoading}
              >
                Create
              </Button>
            </form>
          )}
        />
      </div>
    </Modal>
  );
}
export default CreateConsentForm;
