import * as React from 'react';
import { Modal } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import styles from './PdfViewer.module.scss';
import cx from 'classnames';


function PdfViewer({ isOpen, close, pdfUrl }) {

  return (
      <Modal
        open={isOpen}
        onClose={close}
      >
        <div className={cx("modal", styles.pdfViewer)}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                {pdfUrl && <Viewer fileUrl={pdfUrl} enableSmoothScroll defaultScale={1.3} />}
            </Worker>
        </div>
      </Modal> 
  );
}
export default PdfViewer;