import * as React from 'react';
import styles from './PurchaseConfirmation.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Expired() {
    let navigate = useNavigate();
    return (
            <div className={styles.container}>
                <p>You don't have an active subscription.</p>
                <p>Click here to purchase a plan</p>
                <p>(includes a 14 day trial)</p>
                <Button variant="contained" color="primary" onClick={() => navigate('/plans')}>Plans</Button>
            </div>
    );
}
export default Expired;