import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import '@ant-design/cssinjs';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import MainCard from '../../components/MainCard';
import NewSubscribersChart from './NewSubscribersChart';
import ConversionDonutChart from './ConversionDonutChart';
import PotentialUsersList from './PotentialUsersList';
import DatePickerCard from "../../components/DatePickerCard";
import CircularLoader from '../../components/CircularLoader';
import PDFExportComponent from "../../components/PDFExportComponent";
import Sidebar from "../../menu-items/Sidebar";
/**
 * `NewUsersPage` displays a dashboard specifically designed to provide insights into new user acquisitions
 * and potential conversions. It includes charts and lists such as the Conversion Donut Chart, Potential Users List,
 * and New Subscribers Chart, offering a comprehensive overview of user engagement and growth opportunities.
 * 
 * The page facilitates data-driven decisions by allowing users to filter data based on location, practice, and
 * time period. It features interactive elements such as date pickers, dropdown menus, and toggle buttons to refine
 * the displayed information according to specific criteria.
 * 
 * Uses Material-UI components for layout and styling, Axios for data fetching, and Cookies for authentication.
 * 
 * State Hooks:
 * - iconRotation, filter, size: Control the sorting and filtering UI elements.
 * - locationList, location: Manage the list of locations and the currently selected location.
 * - practiceList, practice: Manage the list of practices and the currently selected practice.
 * - startDate, endDate: Handle the selected date range for filtering the displayed data.
 * 
 * @returns {React.ReactElement} A React functional component representing the New Users page.
 */
const NewUsersPage = () => {
  const [iconRotation, setIconRotation] = useState(0);
  const [filter, setFilter] = useState('practice');
  const [size, setSize] = useState('descend');
  const [locationList, setLocationList] = useState([{ label: "All locations", value: "all" }]);
  const [location, setLocation] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const chartIds = [
    { id: 'maincard_potential_conversions', singleRow: true, scale: 1 },
    { id: 'maincard_new_subscribers', singleRow: true, scale: 2.1 },
    { id: 'box_potential_users', singleRow: true, scale: 0.9 },
    { id: 'new-users-PotentialUsersList', singleRow: true, ignoreMaxHeight: true, scale: 0.9 }
  ];
  const fileName = 'report-new-users.pdf';


  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const toggleSort = () => {
    setSize(prevSlot => prevSlot === 'descend' ? 'ascend' : 'descend');
    setIconRotation(prevRotation => prevRotation === 0 ? 180 : 0);
  };
  const iconStyle = {
    transform: `rotate(${iconRotation}deg)`,
    transition: 'transform 0.3s ease-in-out',
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchLocationList = async () => {
      try {
        const locationURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/locations`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            Authorization: `${token}`
          }
        };
        const locationResponse = await axios.get(locationURL, config);
        if (isMounted) {
          if (locationResponse.data && locationResponse.data.length > 0) {
            const updatedLocationList = [
              { label: "All locations", value: "all" },
              ...locationResponse.data
            ];
            setLocationList(updatedLocationList);
            setLocation(updatedLocationList[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching location data: ', error);
      }
    };

    fetchLocationList();
    return () => {
      isMounted = false;
    };
  }, []);


  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
    <Grid container spacing={2.75}>
      <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>
      {/* Left column */}
      <Grid item xs={12} md={4}>
        <MainCard id='maincard_potential_conversions' content={false} sx={{ height: '225px' }}>
          <Box sx={{ pt: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
              Potential Conversions
            </Typography>
          </Box>
          <Box sx={{ pb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <ConversionDonutChart />
          </Box>
        </MainCard>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}></Box>
        <MainCard sx={{ minHeight: '480px' }}>
          <Box id='box_potential_users'>
            <Box sx={{ pt: 2, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                Potential Users
              </Typography>
            </Box>
            <Box sx={{ p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, margin: 'auto' }}>
              <TextField
                id="location-filter"
                size="small"
                select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                sx={{ width: 150, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
              >
                {locationList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                size="small"
                onClick={toggleSort}
                color="primary"
                variant="outlined"
              >
                Sort by size
                <ArrowDownwardIcon fontSize="inherit" style={iconStyle} />
              </Button>
            </Box>
            <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, margin: 'auto' }}>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={filter}
                exclusive
                onChange={handleFilterChange}
                sx={{ width: 'auto' }}
              >
                <ToggleButton value="practice" sx={{ width: '100px' }}>Practice</ToggleButton>
                <ToggleButton value="dentist" sx={{ width: '100px' }}>Dentist</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box>
            {locationList.length <= 1 ? (
              <CircularLoader />
            ) : (
              <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
                <PotentialUsersList location={location} size={size} filter={filter} />
              </Box>
            )}
          </Box>
        </MainCard>
      </Grid>

      {/* Right column */}
      <Grid item xs={12} md={8} lg={6}>
        <MainCard id='maincard_new_subscribers'>
          <Typography variant="h4" sx={{ pt: 3, fontWeight: 'bold', textAlign: 'center' }}>
            New Subscribers
          </Typography>
          <Box sx={{ pt: 3, pb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
            <DatePickerCard onDateChange={handleDateChange} />
          </Box>
          <Box sx={{ height: '100%', p: 2 }}>
            <NewSubscribersChart startDate={startDate} endDate={endDate} />
          </Box>
        </MainCard>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0 }}>
          <PDFExportComponent
            chartIds={chartIds}
            fileName={fileName}
          />
        </Box>
      </Grid>
    </Grid >
    </Box>
  );
};

export default NewUsersPage;