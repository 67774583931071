import * as React from 'react';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import { Form } from 'react-final-form'
import * as Yup from 'yup';
import {TextField, makeValidate} from 'mui-rff';
import styles from './PurchaseDetails.module.scss';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider'; 
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CardContent from '@mui/material/CardContent';

//validation
const schema = Yup.object().shape({});
const validate = makeValidate(schema);

function PurchaseDetailsForm() {
    const createConsent = useMutation();
    const sendHelpData = data => {}
    return (
        <Form
            onSubmit={sendHelpData}
            validate={validate}
            render={({ handleSubmit }) => (
            <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
                <div className={styles.purchaseDetails}>
                    <h1 className={styles.title}>Purchase Details</h1>
                    <div className={styles.container}>
                        <div className={styles.form}>
                            <h2 className={styles.subTitle}>Contact Details</h2>
                            <Box display="flex" flexDirection="row">
                                <TextField
                                label="First Name"
                                name="firstName"
                                required={true}
                                variant="outlined"
                                style={{ marginRight: "10px" }} 
                                />

                                <TextField
                                label="Last Name"
                                name="lastName"
                                required={true}
                                variant="outlined"
                                />
                            </Box>
                            <TextField
                                label="No of Dental Practices"
                                name="id"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Address"
                                name="id"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Address Line 2"
                                name="id"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="City"
                                name="id"
                                required={true}
                                variant="outlined"
                            />
                            <TextField
                                label="Country"
                                name="id"
                                required={true}
                                variant="outlined"
                            />
                              <Box display="flex" flexDirection="row">
                                <TextField
                                label="State/Province"
                                name="firstName"
                                required={true}
                                variant="outlined"
                                style={{ marginRight: "10px" }} 
                                />

                                <TextField
                                label="Zip code"
                                name="lastName"
                                required={true}
                                variant="outlined"
                                />
                            </Box>
                        </div>
                        <div className={styles.details}>
                            <h2 className={styles.subTitle}>Plan Details</h2>
                            <Divider variant="fullWidth" component="hr" className={styles.divider} />
                                <CardContent>
                                    <List dense={true} className={styles.list}>
                                        <ListItem  className={styles.listItem}>
                                            <TaskAltIcon sx={{ fontSize: 16 }}/>
                                            <ListItemText primary="Practice Owner" />
                                        </ListItem>
                                        <ListItem  className={styles.listItem}>
                                            <TaskAltIcon sx={{ fontSize: 16 }}/>
                                            <ListItemText primary="3-4 Dentist " />
                                        </ListItem>
                                        <ListItem  className={styles.listItem}>
                                            <TaskAltIcon sx={{ fontSize: 16 }}/>
                                            <ListItemText primary="Billed Monthly" />
                                        </ListItem>
                                        <ListItem  className={styles.listItem}>
                                            <TaskAltIcon sx={{ fontSize: 16 }}/>
                                            <ListItemText primary="14 Day Trial then billed Monthly (7th March 2024)" />
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <Box display="flex" flexDirection="row">
                                    <TextField
                                        label="Discount Code"
                                        name="id"
                                        required={true}
                                        variant="outlined"
                                    />
                                    <Box style={{ marginLeft: 10 }}>
                                        <Button className={styles.submitButton} type="submit" variant="contained">
                                        Add
                                        </Button>
                                    </Box>
                                </Box>

                                <Box display="flex" flexDirection="row" mt="20px" justifyContent="space-between">
                                <Typography sx={{fontSize: '1.1rem',fontWeight: 'bold' }} >
                                    Discount
                                </Typography>
                                <Box >
                                    <Typography sx={{fontSize: '1.1rem',fontWeight: 'bold' }} >
                                        £0
                                    </Typography>
                                </Box>
                            </Box>
                            
                            <Box display="flex" flexDirection="row" mt="10px" justifyContent="space-between">
                                <Typography sx={{fontSize: '1.6rem',fontWeight: 'bold' }}>
                                    Total
                                </Typography>
                                <Box>
                                    <Typography sx={{fontSize: '1.1rem',fontWeight: 'bold' }}>
                                        £159 
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider variant="fullWidth" component="hr" className={styles.divider} />

                             <Button className={styles.submitButton} 
                                type="submit" variant="contained">Purchase</Button>
                        </div>
                    </div>
                </div>
            </form>
            )}
        />
    );
}
export default PurchaseDetailsForm;