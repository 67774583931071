import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';


function LoginRedirect() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        login.mutate();
    }, []);

    const login = useMutation(
        () => {
            return axios.post(`/auth?code=${searchParams.get("code")}&state=${searchParams.get("state")}
            &capture=${searchParams.get("capture")}`)
        },
        {
        onSuccess: async (authToken) => {
            Cookies.set('token', authToken.data, { expires: 1/3 });
            axios.defaults.headers.common.Authorization = `Bearer ${authToken.data}`;
            queryClient.invalidateQueries({ queryKey: ['user'] });
            const capture = searchParams.get("capture");
            console.log("capture",capture);
            if (capture === "capture") {
                navigate(`/capture`); 
            } else {
                navigate(`/dashboard`);  
            }
        }
        }
    );
    return null;
}
export default LoginRedirect;