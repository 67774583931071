import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../componenets/CircularLoader';
import axios from 'axios';
import Cookies from 'js-cookie';

const pieChartOptions = {
    chart: {
        type: 'donut',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    labels: [],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val.toFixed(1) + '%';
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(0);
            }
        }
    },
    plotOptions: {
        pie: {
            expandOnClick: true
        }
    },
    legend: {
        position: 'bottom',
        fontSize: '13px',
        itemMargin: {
            horizontal: 10,
            vertical: 5
        }
    },
    colors: ['#626DA1', '#94BBD4', '#E1E1E1', '#FFBD88', '#FFE5E5']
};

/**
 * `ConsentsCompletedChart` is a React component that displays a donut chart of consents completed
 * within a specified date range, for a selected practice and clinician. It fetches data upon initialization
 * and when relevant props change, displaying a loading indicator while fetching and a message when no data
 * matches the filters.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 */
class ConsentsCompletedChart extends React.Component {
    /**
     * Constructor for the ConsentsCompletedChart component.
     * Initializes state and binds methods.
     * @param {Object} props - Component props including `practice` and `clinician`.
     * 
     * @state {Object} state - The internal state of the component.
     * @state {Array} state.series - The data series for the chart.
     * @state {Object} state.options - The chart configuration options.
     * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: pieChartOptions,
            isLoading: false
        };
    }

    /**
     * Lifecycle method to fetch chart data on component update when
     * props indicating filters (practice, clinician) change.
     * @param {Object} prevProps - The previous props of the component.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.practice !== this.props.practice || prevProps.clinician !== this.props.clinician) {
            this.fetchChartData();
        }
    }

    /**
     * Fetches chart data from a specified API endpoint using Axios, updating the component's
     * state with the new data for rendering the chart, or handles errors if the fetch fails.
     */
    fetchChartData() {
        this.setState({ isLoading: true });

        const url = `${process.env.REACT_APP_BASE_URL}users/consents_completed?practice=${this.props.practice}&clinician=${this.props.clinician}`;

        const token = axios.defaults.headers.common.Authorization

        axios.get(url, {
            headers: { "Authorization": `${token}` }
        }).then(response => {
            if (!Array.isArray(response.data)) {
                this.setState({ series: [], options: { ...this.state.options, labels: [] }, isLoading: false });
                return;
            }

            const series = response.data.map(item => item.value);
            const labels = response.data.map(item => item.label);

            this.setState({
                series,
                options: { ...this.state.options, labels },
                isLoading: false
            });

        }).catch(error => {
            console.error('Error fetching chart data:', error);
            this.setState({ isLoading: false });
        });
    }

    /**
     * Lifecycle method to fetch chart data on component mount.
     */
    componentDidMount() {
        this.fetchChartData();
    }

    /**
     * Renders the donut chart or a loading/error message as appropriate.
     * @returns {ReactNode} The React node corresponding to the component UI.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
        };

        const allValuesAreZero = this.state.series.every(value => value === 0);

        return (
            <div id="user-homepage-ConsentsCompletedChart" style={this.state.isLoading ? containerStyle : {}}>
                {this.state.isLoading ? (
                    <div data-testid="loader">
                        <CircularLoader />
                    </div>
                ) : allValuesAreZero ? (
                    <div style={containerStyle}>No data matches these filters.</div>
                ) : (
                    <ReactApexChart data-testid="chart" options={this.state.options} series={this.state.series} type="donut" width={400} />
                )}
            </div>
        );
    }

}

export default ConsentsCompletedChart;
