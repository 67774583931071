import * as React from 'react';
import Button from '@mui/material/Button';
import { Form } from 'react-final-form'
import * as Yup from 'yup';
import {TextField, makeValidate, Autocomplete} from 'mui-rff';
import styles from './Landing.module.scss';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { isEmpty } from 'lodash';

const schema = Yup.object().shape({
  id: Yup.string().required(),
  practice_id: Yup.string().required(),
});
 
// Run the makeValidate function...
const validate = makeValidate(schema);

function Landing() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [practiceList, setPracticeList] = React.useState<{label: string; value: string}[]>([]);
  
  const createConsent = useMutation(
    (data: {id: string; practice_id: string}) => {
        return axios.post(`/consent_bundle/submit_treatment_plan_for_consent_bundle_creation`, data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['patients'] })
        if (data.data.status === 'Draft') {
          navigate(`/${data.data.id }`)
        } else {
          navigate(`/${data.data.id }/summary`)
        }
      }
    }
  );

  const getPractice = useQuery(
    ['practices'],
    async () => {
      const { data } = await axios.get(`/practice`);
      return data;
    },
    {
      onSuccess: data => {
        const list = data.map(p => ({label: p.name, value: p.id}));
        setPracticeList(list);
      },
      enabled: isEmpty(practiceList)
    }
  );

  const createOrOpenConsent = data => {
    createConsent.mutate(data);
  }

  return (
    <div className={styles.landing}>
        <Form
        onSubmit={createOrOpenConsent}
        validate={validate}
        render={({ handleSubmit }) => (
        <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
                <h1>Access consent documents</h1>
                <Autocomplete
                  label="Practice Name"
                  name="practice_id"
                  required={true}
                  className={styles.dropdown}
                  options={practiceList}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                />
                <TextField
                    label="Treatment Plan ID"
                    name="id"
                    required={true}
                    variant="outlined"
                />
            <Button className={styles.submitButton} type="submit" variant="contained" disabled={createConsent.isLoading}>Proceed</Button>
        </form>
        )}
    />
    </div>
  );
}
export default Landing;