import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';
import axios from 'axios'; 
import { toast } from 'react-toastify'; 

const CameraCapture = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImages, setCapturedImages] = useState([]);
  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const constraints = { video: { facingMode: { ideal: 'environment' } } };
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });

        if (permissionStatus.state === 'denied') {
          setCameraAvailable(false);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
          setCameraAvailable(true);
        }

        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'granted') {
            getCameraStream();
          } else if (permissionStatus.state === 'denied') {
            setCameraAvailable(false);
          }
        };
      } catch (error) {
        console.error('Error accessing camera:', error);
        setCameraAvailable(false);
      }
    };

    const fetchAdminStatus = async () => {
      try {
        const { data } = await axios.get('/user/is_admin');
        setIsAdmin(data.isAdmin);
      } catch (error) {
        console.error('Error fetching admin status:', error);
      }
    };

    getCameraStream();
    fetchAdminStatus();

    return () => {
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
        }
      }
    };
  }, []);

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0);
      const imageData = canvas.toDataURL('image/png');
      setCapturedImages((prevImages) => [...prevImages, imageData]);
    }
  };

  const uploadCallback = (base64Image) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const blob = dataURLToBlob(base64Image);
      formData.append('file', blob, 'captured_image.png'); 

      axios
        .post('/consent_clause/upload_image_to_s3', formData)
        .then((response) => {
          resolve({ data: { link: response.data } });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleUploadImage = async (image) => {
    setIsUploading(true); 
    try {
      const response = await uploadCallback(image);
      toast.success('Image uploaded successfully!'); 
      setCapturedImages([]);
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed uploading image.');
    } finally {
      setIsUploading(false);
    }
  };

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const handleOpenSettings = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS instructions
      alert("To enable camera access, go to your device's Settings > Safari > Camera and allow access.");
    } else if (/android/i.test(userAgent)) {
      // Android instructions
      alert("To enable camera access, go to your device's Settings > Apps > [Browser] > Permissions > Camera and enable it.");
    } else {
      alert("Please enable camera permissions in your browser's settings.");
    }
  };
  return (
    <Box sx={{ padding: 2 }}>
      {isMobile ? (
        cameraAvailable ? (
          <>
            <Box sx={{ position: 'relative', width: '100%', height: '400px', borderRadius: 2, overflow: 'hidden', mb: 2 }}>
              <Typography variant="h6" sx={{ position: 'absolute', top: 10, left: 10, color: 'white' }}>
                Camera
              </Typography>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <Button
                variant="contained"
                onClick={captureImage}
                sx={{ position: 'absolute', bottom: 10, right: 10 }}
                disabled={isUploading} 
              >
                Capture
              </Button>
            </Box>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Captured Images
            </Typography>
            <Grid container spacing={2}>
              {capturedImages.map((image, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <img src={image} alt={`Captured ${index}`} style={{ width: '100%', borderRadius: 8 }} />
                  <Button
                    variant="contained"
                    onClick={() => handleUploadImage(image)} 
                    sx={{ mt: 1 }}
                    disabled={isUploading} 
                  >
                    Save
                  </Button>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" color="error">
              Camera not accessible. Please enable camera access.
            </Typography>
            <Button variant="contained" onClick={handleOpenSettings} sx={{ mt: 2 }}>
              Go to Camera Settings
            </Button>
          </>
        )
      ) : (
        <Typography variant="h6" color="error">
          Please use a mobile device to capture images.
        </Typography>
      )}
    </Box>
  );
};

export default CameraCapture;
