import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'; 
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import styles from './PlansCard.module.scss';

export default function PlansCardSimple({title, price, features, actions, oldPrice}) {
  return (
        <Card className={styles.planCard}>
          <CardContent>
            <Typography className={styles.title}>
              {title}
            </Typography>
          </CardContent>
          <Divider variant="fullWidth" component="hr" className={styles.divider} />
          <CardContent>
              <List dense={true} className={styles.list}>
                {features.map((feature, index) => (
                  <ListItem key={index} className={styles.listItem}>
                    <TaskAltIcon sx={{ fontSize: 16 }}/>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
          </CardContent>
        
          <CardContent>
            {oldPrice && <Typography className={styles.oldPrice}>{oldPrice}</Typography>}
            <Typography className={styles.price}>{price}</Typography>
          </CardContent>
          <CardActions className={styles.footer}>
              {actions.map((action, index) => (
                <Button key={index} variant="contained" className={styles.action} onClick={action.action} disabled={action.disabled}>{action.label}</Button>
              ))}
          </CardActions>
        
        </Card>
  );
}
