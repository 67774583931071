import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import Cookies from 'js-cookie';
import CircularLoader from '../../components/CircularLoader';

const areaChartOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val.toFixed(1) + '%';
      }
    }
  },
  grid: {
    strokeDashArray: 0
  },
  colors: ['#626DA1', '#FFBD88'],
  yaxis: {
    min: 0
  },
};

/**
 * `RetentionRateChart` displays a line chart representing retention rates over a specified time period.
 * It fetches chart data from an API using provided startDate and endDate props as filters. This component
 * manages its loading state to display a loader while fetching data and handles the display when no data
 * matches the filters.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.startDate - The starting date for the retention rate query.
 * @param {string} props.endDate - The ending date for the retention rate query.
 */
class RetentionRateChart extends React.Component {
  /**
   * Initializes the RetentionRateChart component with default state including chart options
   * and an empty series. Sets isLoading to true until data is fetched successfully.
   * @param {Object} props - The props passed to the component.
   * 
   * @state {Object} state - The internal state of the component.
   * @state {Array} state.series - The data series for the chart, initially empty.
   * @state {Object} state.options - Configuration options for the ApexCharts chart.
   * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched, initially false.
   */
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: areaChartOptions,
      isLoading: false
    };
  }

  /**
   * Called after the component updates, to fetch new data if the startDate or endDate props have changed.
   * @param {Object} prevProps - Previous props for comparison to determine if a data fetch is necessary.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate) {
      this.fetchChartData();
    }
  }

  /**
   * Fetches chart data from the specified API endpoint using Axios. Updates the component's
   * state with the new data for rendering the chart or logs an error if the fetch fails.
   */
  fetchChartData() {
    this.setState({ isLoading: true });

    const { startDate, endDate } = this.props;

    const url = `${process.env.REACT_APP_BASE_URL}admin/retention_rates?startDate=${startDate}&endDate=${endDate}`;

    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };

    axios.get(url, config)
      .then(response => {
        const apiData = response.data;
        const newSeries = apiData.Series.map(item => {
          return {
            name: item.label,
            data: item.value
          };
        });

        this.setState({
          series: newSeries,
          options: {
            ...this.state.options,
            xaxis: {
              categories: apiData.Months
            }
          },
          isLoading: false
        });
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
        this.setState({ isLoading: false });
      });
  }

  /**
   * React lifecycle method called immediately after the component is mounted.
   * It triggers the initial data fetch for the chart based on the startDate and endDate props.
   */
  componentDidMount() {
    this.fetchChartData();
  }

  /**
   * Renders the line chart using ReactApexChart with the fetched data or displays
   * a loading state while data is being fetched.
   * 
   * @returns {React.ReactElement} The rendered component, which might include a loader, error message, or the chart itself.
   */
  render() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px'
    };
    const { isLoading, options, series } = this.state;
    const allValuesAreZero = this.state.series.every(value => value === 0);
    return (
      <div id="existing-users-RetentionRateChart" style={this.state.isLoading ? containerStyle : {}}>
        {isLoading ? (
          <div data-testid="loader">
            <CircularLoader />
          </div>
        ) : allValuesAreZero ? (
          <div style={containerStyle}>No data matches these filters.</div>
        ) : (
          <ReactApexChart data-testid="chart" options={options} series={series} type="line" height={530} />
        )}
      </div>
    );
  }
}

export default RetentionRateChart;