import { Button } from '@mui/material';
import * as React from 'react';
import styles from './PurchaseConfirmation.module.scss';
import { useNavigate } from 'react-router-dom';

function PurchaseConfirmation() {
    let navigate = useNavigate();
    return (
            <div className={styles.container}>
                <h3>Purchase Confirmation</h3>
                <p>Your Subscription is now active.</p>
                <Button variant="contained" color="primary" onClick={() => navigate('/my-plan')}>Manage Plan</Button>
            </div>
    );
}
export default PurchaseConfirmation;