import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import MainCard from '../../componenets/MainCard';
import ScoreBarChart from './ScoreBarChart';
import ScoreLineChart from './ScoreLineChart';
import Loader from '../../componenets/Loader';
import PDFExportComponent from '../../componenets/PDFExportComponent';
import Sidebar from '../../menu-items/Sidebar';

/**
 * `ComplianceLeaderboardUser` component serves as a dynamic leaderboard display for compliance scores related
 * to 'consent management', 'patient engagement', and a 'combined score'. It allows users to switch between
 * these categories to view specific leaderboard scores. This component fetches and displays data for each category
 * based on the selected practice. Additionally, it supports exporting the displayed leaderboard as a PDF file.
 *
 * Uses `MainCard` for layout structure, `ScoreBarChart` and `ScoreLineChart` for displaying the scores in
 * bar and line chart formats respectively, and `PDFExportComponent` for exporting the data.
 * 
 * The component fetches practice list on component mount and updates the displayed leaderboard based on user's
 * selection of practice and score category.
 */
const ComplianceLeaderboardUser = () => {
  const [slot, setSlot] = useState('consent management');
  const [practiceList, setPracticeList] = useState([]);
  const [practice, setPractice] = useState('');

  const filters = {
    practice: practice
  };
  const chartIds = [
    { id: 'maincard_score_line_chart', singleRow: true, scale: 2.5 }
  ];
  const fileName = 'report-user-compliance-leaderboard.pdf';

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            "Authorization": `${token}`
          }
        };
    
        const practiceResponse = await axios.get(practiceURL, config);
        if (isMounted) {
          if (practiceResponse.data && practiceResponse.data.length > 0) {
            setPracticeList(practiceResponse.data);
            setPractice(practiceResponse.data[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, []);

  if (practiceList.length < 1) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar sx={{ width: 250, flexShrink: 0 }} />
      <Box sx={{ flexGrow: 1, p: 4 }}>
        <Grid container spacing={2.5}>
          {/* row 1 */}
          <Grid item xs={12} md={12} lg={12}>
            <MainCard content={false} sx={{ height: 'auto', p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black' }}>
                  Leaderboard
                </Typography>
                <Box sx={{ pt: 3, pb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                  <TextField
                    id="practice-filter"
                    size="small"
                    select
                    value={practice}
                    onChange={(e) => setPractice(e.target.value)}
                    sx={{ width: '100%', maxWidth: 200, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {practiceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </MainCard>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <MainCard content={false} sx={{ height: 'auto', p: 2 }}>
              <ScoreBarChart practice={practice} />
            </MainCard>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <MainCard id='maincard_score_line_chart' content={false} sx={{ height: '450px', p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    size="small"
                    onClick={() => setSlot('consent management')}
                    color={slot === 'consent management' ? 'primary' : 'secondary'}
                    variant={slot === 'consent management' ? 'outlined' : 'text'}
                    sx={{ textTransform: 'none' }}
                  >
                    Consent management
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setSlot('patient engagement')}
                    color={slot === 'patient engagement' ? 'primary' : 'secondary'}
                    variant={slot === 'patient engagement' ? 'outlined' : 'text'}
                    sx={{ textTransform: 'none' }}
                  >
                    Patient engagement
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setSlot('overall')}
                    color={slot === 'overall' ? 'primary' : 'secondary'}
                    variant={slot === 'overall' ? 'outlined' : 'text'}
                    sx={{ textTransform: 'none' }}
                  >
                    Combined score
                  </Button>
                </Stack>
                <Box sx={{ pt: 2, width: '100%' }}>
                  <ScoreLineChart practice={practice} slot={slot} />
                </Box>
              </Box>
            </MainCard>
          </Grid>

          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="caption" align="left">
                ©2024 Medicube
              </Typography>
            </Grid>
            <Grid item>
              <PDFExportComponent
                filters={filters}
                chartIds={chartIds}
                fileName={fileName}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ComplianceLeaderboardUser;
