import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../componenets/CircularLoader';
import Cookies from "js-cookie";
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  },
  colors: ['#626DA1', '#94BBD4', '#A8A8A8', '#FFBD88']
};

/**
 * `MonthlyAverageChart` is a React component that renders an area chart displaying
 * monthly average data based on selected practice, clinician, startDate and endDate.
 * It fetches data from an API upon initialization and when the props change,
 * displaying a loading indicator while data is being fetched.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.practice - Selected practice for filtering the chart data.
 * @param {string} props.clinician - Selected clinician for filtering the chart data.
 * @param {string} props.startDate - Start date for the date range filter.
 * @param {string} props.endDate - End date for the date range filter.
 */
class MonthlyAverageChart extends React.Component {
  /**
 * Constructs the `MonthlyAverageChart` component with initial state.
 * @param {Object} props - Component props including `practice`, `clinician`, `startDate` and `endDate`.
 * 
 * @state {Object} state - The internal state of the component.
 * @state {Array} state.series - The data series for the chart.
 * @state {Object} state.options - The chart configuration options.
 * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
 */
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: areaChartOptions,
      isLoading: false
    };
  }

  /**
   * React lifecycle method, called after the component updates, to fetch new data
   * if the `practice`, `clinician`, `startDate` or `endDate` props have changed.
   * @param {Object} prevProps - Previous props for comparison.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.practice !== this.props.practice ||
      prevProps.clinician !== this.props.clinician ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate) {
      this.fetchChartData();
    }
  }

  /**
   * Fetches chart data from the specified API endpoint using Axios, updating the
   * component's state with the new data for rendering the chart, or logs errors if
   * the fetch fails.
   */
  fetchChartData() {
    this.setState({ isLoading: true });

    const { practice, clinician, startDate, endDate } = this.props;

    let url = `${process.env.REACT_APP_BASE_URL}users/average_monthly_consents_completed?practice=${practice}&clinician=${clinician}`;
    if (startDate && endDate) {
      url += `&startDate=${startDate}&endDate=${endDate}`;
    }

    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        "Authorization": `${token}`
      }
    };

    axios.get(url, config)
      .then(response => {
        const apiData = response.data;
        const newSeries = apiData.Series.map(item => {
          return {
            name: item.label,
            data: item.value
          };
        });

        this.setState({
          series: newSeries,
          options: {
            ...this.state.options,
            xaxis: {
              categories: apiData.Months
            }
          },
          isLoading: false
        });
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
        this.setState({ isLoading: false });
      });
  }

  /**
   * React lifecycle method, called immediately after the component is mounted,
   * triggering the initial data fetch for the chart.
   */
  componentDidMount() {
    this.fetchChartData();
  }

  /**
   * Renders the component based on the current state. Displays a loading indicator
   * while data is being fetched, a message if no data matches the filters, or the
   * chart itself if data is available.
   * @returns {React.ReactElement} The rendered component.
   */
  render() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px'
    };
    const { isLoading, options, series } = this.state;
    const allValuesAreZero = this.state.series.every(series =>
      series.data.every(value => value === 0)
    );
    return (
      <div id="user-compliance-management-MonthlyAverageChart" style={this.state.isLoading ? containerStyle : {}}>
        {isLoading ? (
          <div data-testid="loader">
            <CircularLoader />
          </div>
        ) : allValuesAreZero ? (
          <div style={containerStyle}>No data matches these filters.</div>
        ) : (
          <ReactApexChart data-testid="chart" options={options} series={series} type="area" height={540} width="100%" />
        )}
      </div>
    );
  }
}

export default MonthlyAverageChart;