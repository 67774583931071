import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import MainCard from '../../componenets/MainCard';
import ScoreBarChart from './ScoreBarChart';
import VideoCompletionsChart from './VideoCompletionsChart';
import VideoTable from './VideoTable';
import FeedbackTable from './FeedbackTable';
import Loader from '../../componenets/Loader';
import PDFExportComponent from '../../componenets/PDFExportComponent';
import Sidebar from '../../menu-items/Sidebar';

const PatientPageUser = () => {
  const [practiceList, setPracticeList] = useState([{ label: "All practices", value: "all" }]);
  const [practice, setPractice] = useState('all');
  const [clinicianList, setClinicianList] = useState([{ label: "All clinicians", value: "all" }]);
  const [clinician, setClinician] = useState('all');

  const filters = { practice, clinician };
  const chartIds = [
    { id: 'maincard_patient_video_completions', singleRow: true, scale: 1.1 },
    { id: 'box_patient_feedback', singleRow: true, scale: 0.5 },
    { id: 'user-patient-engagement-FeedbackTable', singleRow: true, ignoreMaxHeight: true, scale: 1 },
    { id: 'box_average_video_engagement', singleRow: true, scale: 0.8 },
    { id: 'user-patient-engagement-VideoTable', singleRow: true, ignoreMaxHeight: true, scale: 1 },
  ];
  const fileName = 'report-patient-engagement.pdf';

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
        const token = axios.defaults.headers.common.Authorization;
        const config = { headers: { "Authorization": `${token}` } };

        const practiceResponse = await axios.get(practiceURL, config);
        if (isMounted && practiceResponse.data && practiceResponse.data.length > 0) {
        const updatedPracticeList = [{ value: "1" }, ...practiceResponse.data];
        setPracticeList(updatedPracticeList);
            setPractice(updatedPracticeList[1].value);
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchClinicianList = async () => {
      try {
        if (practice) {
          const clinicianURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/clinicians?practice=${practice}`;
          const token = axios.defaults.headers.common.Authorization;
          const config = { headers: { "Authorization": `${token}` } };

          const clinicianResponse = await axios.get(clinicianURL, config);
          if (isMounted && clinicianResponse.data && clinicianResponse.data.length > 0) {
            const updatedClinicianList = [{ value: "1" }, ...clinicianResponse.data];
            setClinicianList(updatedClinicianList);
            setClinician(updatedClinicianList[1].value);
          }
        }
      } catch (error) {
        console.error('Error fetching clinician data: ', error);
      }
    };

    fetchClinicianList();
    return () => {
      isMounted = false;
    };
  }, [practice]);

  if (practiceList.length <= 1 || clinicianList.length <= 1) {
    return <Loader />;
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Grid container spacing={2.5}>
            {/* Filter and Title Row */}
            <Grid item xs={12} md={5} lg={4}>
              <MainCard content={false} sx={{ height: '100%', p: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    Patient Engagement
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                    <TextField
                      id="practice-filter"
                      size="small"
                      select
                      value={practice}
                      onChange={(e) => setPractice(e.target.value)}
                      sx={{ width: 120 }}
                    >
                      {practiceList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="clinician-filter"
                      size="small"
                      select
                      value={clinician}
                      onChange={(e) => setClinician(e.target.value)}
                      sx={{ width: 120 }}
                    >
                      {clinicianList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
              </MainCard>
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
              <MainCard content={false} sx={{ height: '100%' }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                    Patient Engagement Score
                  </Typography>
                  <ScoreBarChart practice={practice} clinician={clinician} />
                </Box>
              </MainCard>
            </Grid>

            {/* Charts and Tables Row */}
            <Grid item xs={12} md={4} lg={4}>
              <MainCard id='maincard_patient_video_completions' content={false} sx={{ height: '100%' }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                    Patient Video Completions
                  </Typography>
                  <VideoCompletionsChart practice={practice} clinician={clinician} />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <MainCard content={false} sx={{ height: '100%' }}>
                <Box sx={{ p: 2 }}>
                  <Typography id='box_average_video_engagement' variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                    Average Video Engagement
                  </Typography>
                  <VideoTable practice={practice} clinician={clinician} />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <MainCard content={false} sx={{ height: '100%' }}>
                <Box sx={{ p: 2 }}>
                  <Typography id='box_patient_feedback' variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                    Patient Feedback
                  </Typography>
                  <FeedbackTable practice={practice} clinician={clinician} />
                </Box>
              </MainCard>
            </Grid>
          </Grid>

          {/* Footer Row */}
          <Grid container item xs={12} sx={{ mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">©2024 Medicube</Typography>
            <PDFExportComponent filters={filters} chartIds={chartIds} fileName={fileName} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientPageUser;
