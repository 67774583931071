import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import PlaylistModal from "./PlaylistModal";
import {
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Skeleton,
} from "@mui/material";
import { Form, Field } from "react-final-form";
import { Select as RffSelect } from "mui-rff";
import styles from "./VideoManager.module.scss";
import debounce from "lodash.debounce";
import TikTok from "react-tiktok";
import YouTube, { YouTubeProps } from "react-youtube";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { confirmAlert } from "react-confirm-alert";
import ClearIcon from "@mui/icons-material/Clear";
import { Clear } from "@mui/icons-material";


export default function VideoManager() {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [userid, setUserId] = useState("");
  const [practiceId, setPracticeId] = useState("");
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalPlaylist, setOpenModalPlaylist] = useState(false);
  const queryClient = useQueryClient();
  const startTimeRef = useRef(null);
  const playbackTimeRef = useRef(0);
  const [playlistData, setPlaylistData] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const [lastPlayedSeconds, setLastPlayedSeconds] = useState(null);
  const [playedBeforePause, setPlayedBeforePause] = useState(null);

  const treatment_Categories = useQuery(["categories"], async () => {
    const { data } = await axios.post(`/treatment_category`);
    return data;
  });

  const { data: playlistCategories } = useQuery(
    ["playlistCategories"],
    async () => {
      const { data } = await axios.post(`/treatment_category`);

      return data;
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const treatmentResponse = await axios.get("/treatment_category");
        const treatmentOptions = treatmentResponse.data.map((plan) => ({
          label: plan.category_name,
          value: plan.id,
        }));
        setTreatments(treatmentOptions);
        const userResponse = await axios.get("/user");
        setUserId(userResponse.data[1].medicube_user_id);
        setPracticeId(userResponse.data[1].medicube_practice_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleData();
    fetchData();
  }, []);

  const handleData = () => {
    setLoading(true);
    axios
      .get(`/playlist/`)
      .then((response) => {
        const data = response.data.map((item) => {
          const videos = item.playist_items || [];
          const firstVideoTitle =
            videos.length > 0
              ? videos[0].title || `Category ${item.id}`
              : `Category ${item.id}`;
          return {
            id: item.id,
            name: item.title || firstVideoTitle,
            videos: videos.map((video) => ({
              title: video.title,
              url: video.url,
              thumbnail: video.thumbnail,
              description: video.description,
              platform: video.platform,
              duration: video.duration,
            })),
          };
        });
        setCategories(data);

        if (data.length > 0) {
          setCurrentCategory(data[0]);
          setSelectedCategoryId(data[0].id);

          axios
            .get(`/playlist/${data[0].id}`)
            .then((playlistResponse) => {
              setSelectedCategoryName(
                playlistResponse.data.treatment_category_name
              );
            })
            .catch((error) => {
              console.error("Error fetching the playlist data:", error);
            });

          if (data[0].videos.length > 0) {
            setCurrentVideo(data[0].videos[0]);
          } else {
            setCurrentVideo(null);
          }
        } else {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the playlist data:", error);
        setLoading(false);
      });
  };

  const fetchPlaylistsByTreatment = (treatmentId) => {
    setLoading(true);
    axios
      .get(`/playlist/?treatment_category_id=${treatmentId}`)
      .then((response) => {
        console.log("Response Data:", response.data);
        const data = response.data.map((item) => {
          const videos = item.playist_items || [];
          const firstVideoTitle =
            videos.length > 0
              ? videos[0].title || `Category ${item.id}`
              : `Category ${item.id}`;
          return {
            name: item.title || firstVideoTitle,
            videos: videos.map((video) => ({
              title: video.title,
              url: video.url,
              thumbnail: video.thumbnail,
            })),
          };
        });
        setCategories(data);
        if (data.length > 0) {
          setCurrentCategory(data[0]);
          if (data[0].videos.length > 0) {
            setCurrentVideo(data[0].videos[0]);
          } else {
            setCurrentVideo(null);
          }
        } else {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the playlist data:", error);
        setLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce(() => {
      if (searchParam.trim() === "") {
        handleData();
        return;
      }

      setSearchLoading(true);
      axios
        .get(`/playlist/?playlist_name=${searchParam}`)
        .then((response) => {
          const data = response.data.map((item) => {
            const firstVideoTitle =
              item.playist_items.length > 0
                ? item.playist_items[0].title || `Category ${item.id}`
                : `Category ${item.id}`;
            return {
              name: item.title || firstVideoTitle,
              videos: item.playist_items.map((video) => ({
                title: video.title,
                url: video.url,
                thumbnail: video.thumbnail,
              })),
            };
          });
          setCategories(data);
          if (data.length > 0) {
            setCurrentCategory(data[0]);
            if (data[0].videos.length > 0) {
              setCurrentVideo(data[0].videos[0]);
            } else {
              setCurrentVideo(null);
            }
          } else {
            setCurrentCategory(null);
            setCurrentVideo(null);
          }
          setSearchLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching the playlist data:", error);
          setSearchLoading(false);
        });
    }, 300),
    [searchParam]
  );

  useEffect(() => {
    if (searchParam.trim() !== "") {
      handleSearch();
    }
  }, [searchParam, handleSearch]);

  useEffect(() => {
    if (selectedTreatment) {
      fetchPlaylistsByTreatment(selectedTreatment);
    }
  }, [selectedTreatment]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchParam(value);
  };

  const onCategorySelect = async (category) => {
    setSelectedCategoryId(category.id);
    try {
      console.log("category", category.id);
      const response = await axios.get(`/playlist/${category.id}`);
      console.log("Playlist Data:", response);
      setSelectedCategoryName(response.data.treatment_category_name);

      setCurrentCategory(category);
      if (category.videos.length > 0) {
        setCurrentVideo(category.videos[0]);
      } else {
        setCurrentVideo(null);
      }

      logUserAction("Treatment Selected", "User clicked on a treatment", {
        treatment: category.name,
      });
    } catch (error) {
      console.error("Error fetching playlist:", error);
    }
  };

  const onVideoSelect = (video) => {
    logCurrentVideoTime();
    setCurrentVideo(video);

    logUserAction("Video Selected", "User clicked on a Video", {
      video: video.title,
    });
    resetTimeTracking();
  };

  const handleTreatmentChange = (event) => {
    setSelectedTreatment(event.target.value);
  };

  const logCurrentVideoTime = () => {
    if (startTimeRef.current && currentVideo) {
      const endTime = Date.now();
      const duration = (endTime - startTimeRef.current) / 1000;
      const actualPlaybackTime = playbackTimeRef.current;

      logUserAction("Video Watched", "User stopped watching a video", {
        video: currentVideo.title,
        duration: duration,
        actualPlaybackTime: actualPlaybackTime,
      });
    }
  };

  const logUserAction = (event, description, data) => {
    if (!userid) {
      console.warn("User ID is not defined. Log action is not sent.");
      return;
    }

    axios
      .post(`/log`, {
        type: "info",
        description: description,
        event: event,
        data: data,
        practice_id: practiceId,
        user_id: userid,
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error logging user action:", error);
      });
  };

  const resetTimeTracking = () => {
    startTimeRef.current = Date.now();
    playbackTimeRef.current = 0;
  };

  const handleVideoStart = () => {
    resetTimeTracking();
  };
  const [seekTime, setSeekTime] = useState(null);

  const handleVideoProgress = (state) => {
    setLastPlayedSeconds(state.playedSeconds);
    playbackTimeRef.current = state.playedSeconds;
  };

  const handleVideoPause = () => {
    setPlayedBeforePause(lastPlayedSeconds);
    handleVideoResume();
    logCurrentVideoTime();
    resetTimeTracking();
  };

  const handleVideoResume = () => {
    const newPlayedSeconds = lastPlayedSeconds;
    const calculatedSeekTime = newPlayedSeconds - (playedBeforePause || 0);
    setSeekTime(calculatedSeekTime);
    // console.log("Seek time: ", calculatedSeekTime);

    // Clear
    setSeekTime(null);
  };

  const handleVideoEnd = () => {
    logCurrentVideoTime();
    resetTimeTracking();
  };

  const handleModalOpen = () => setOpenModalPlaylist(true);
  const handleModalClose = () => setOpenModalPlaylist(false);

  if (loading) {
    return <CircularProgress />;
  }

  const handleSubmitPlaylistSave = async (formValues) => {
    try {
      const { playlistName, category_id } = formValues;
      const categoryIdInt = parseInt(category_id, 10);

      const response = await axios.post("/playlist/", {
        title: playlistName,
        treatment_category_id: categoryIdInt,
      });

      const newPlaylist = {
        id: response.data,
        name: playlistName,
        videos: [],
      };
      setCategories((prevCategories) => [...prevCategories, newPlaylist]);
      setCurrentCategory(newPlaylist);
      setSelectedCategoryId(newPlaylist.id);
      setCurrentVideo(null);
      toast.success("Playlist created successfully");
      handleModalClose();
    } catch (error) {
      toast.error("Error saving playlist.");
      console.error("Error saving playlist:", error);
    }
  };

  const handleDeletePlaylist = (playlistId) => {
    axios
      .delete(`/playlist/${playlistId}`)
      .then(() => {
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category.id !== playlistId)
        );
        if (currentCategory?.id === playlistId) {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }
        toast.success("Playlist deleted successfully");
      })
      .catch((error) => {
        toast.error("Error deleting playlist.");
        console.error("Error deleting playlist:", error);
      });
  };

  const handleModalOpenPlaylist = () => setOpenModal(true);

  const handleSubmitPlaylistItemSave = async (newVideo) => {
    try {
      const updatedCategory = { ...currentCategory };
      updatedCategory.videos.push(newVideo);

      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === updatedCategory.id ? updatedCategory : cat
        )
      );

      setCurrentCategory(updatedCategory);
      setCurrentVideo(newVideo);
      setOpenModal(false);
    } catch (error) {
      toast.error("Error saving playlist item.");
      console.error("Error saving playlist item:", error);
    }
  };

  return (
    <Box className={styles.app}>
      <h1 className={styles.header}>Video Gallery</h1>

      <Dialog
        open={openModalPlaylist}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            width: "600px",
            borderRadius: "15px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>Add New Playlist</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmitPlaylistSave}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mt={2}>
                  <Field name="playlistName">
                    {({ input }) => (
                      <TextField
                        label="Playlist Name"
                        variant="outlined"
                        fullWidth
                        {...input}
                        autoFocus
                      />
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <Field name="category_id">
                    {({ input }) => (
                      <TextField
                        select
                        label="Category"
                        variant="outlined"
                        fullWidth
                        {...input}
                        margin="normal"
                      >
                        {playlistCategories?.map((category) => (
                          <MenuItem value={category.id} key={category.id}>
                            {category.category_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Box>

                <DialogActions>
                  <Button onClick={handleModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box className={styles.categoriesBox}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <h2>Playlist</h2>
              <Button variant="contained" onClick={handleModalOpen}>
                + Add New Playlist
              </Button>
            </Box>

            <Box
              className={styles.searchFilterBox}
              display="flex"
              alignItems="center"
            >
              <TextField
                label="Search"
                variant="filled"
                value={searchParam}
                name="searchParam"
                onChange={handleInputChange}
                fullWidth
              />
              <Box mt={1} />
              <Select
                value={selectedTreatment}
                onChange={handleTreatmentChange}
                displayEmpty
                variant="filled"
                fullWidth
                className={styles.selectBox}
                style={{ marginLeft: "8px" }} // Space between input and select
              >
                <MenuItem value="" disabled>
                  Filter by Category
                </MenuItem>
                {treatments.map((treatment) => (
                  <MenuItem key={treatment.value} value={treatment.value}>
                    {treatment.label}
                  </MenuItem>
                ))}
              </Select>
              
            </Box>
           

            {(searchParam || selectedTreatment) && (
  <Button
    type="button"
    title="Clear Filters"
    onClick={() => {
      setSearchParam(""); // Clear the search parameter
      setSelectedTreatment(""); // Clear the selected treatment
      window.location.reload(); // Refresh the page
    }}
    className={styles.clear}
  >
    <Clear /> Clear filters
  </Button>
)}

            {searchLoading && <CircularProgress />}
            <div>
              <Box mt={6} sx={{ maxHeight: "750px", overflowY: "auto" }}>
                {categories.length === 0 ? (
                  <Skeleton variant="rectangular" height={118} />
                ) : (
                  categories.map((category, index) => (
                    <div
                      key={index}
                      className={styles.categoryItem}
                      onClick={() => onCategorySelect(category)}
                    >
                      <span className={styles.categoryName}>
                        {category.name}
                      </span>
                      {(!category.videos[0] ||
                        !category.videos[0].platform ||
                        category.videos[0].platform !== "s3") && (
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            confirmAlert({
                              title: "Warning",
                              message:
                                "Are you sure you want to delete this playlist?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: async () => {
                                    handleDeletePlaylist(category.id);
                                  },
                                },
                                {
                                  label: "Cancel",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }}
                          className={styles.deleteIcon}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  ))
                )}
              </Box>
            </div>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box className={styles.box}>
            <h1>{currentCategory?.name}</h1>
            <div className={styles.playerContainer}>
              {currentVideo || categories.length === 0 ? (
                <ReactPlayer
                  url={currentVideo?.url}
                  controls
                  width="70%"
                  height="500px"
                  onStart={() => {
                    //console.log("Video started");
                    handleVideoStart();
                  }}
                  onProgress={(state) => {
                    handleVideoProgress(state);
                  }}
                  onPause={() => {
                    handleVideoPause();
                  }}
                  onEnded={() => {
                    // console.log("Video ended");
                    handleVideoEnd();
                  }}
                  onPlay={() => {
                    handleVideoResume();
                  }}
                  onSeek={() => {
                    //console.log("Video SEEK");
                    handleVideoSeek();
                  }}
                />
              ) : (
                <div> No videos available</div>
              )}

              <div className={styles.playlist}>
                <h2>Videos ({selectedCategoryName})</h2>

                {categories.length === 0 ? (
                  <Skeleton variant="rectangular" height={118} />
                ) : (
                  currentCategory?.videos.map((video, index) => (
                    <div
                      key={index}
                      className={`${styles.playlistItem} ${
                        video === currentVideo ? styles.active : ""
                      }`}
                      onClick={() => onVideoSelect(video)}
                    >
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className={styles.thumbnail}
                      />
                      <div>{video.title}</div>
                    </div>
                  ))
                )}
                {/* Hide the button if the platform of the first video is S3 */}
                {currentCategory?.videos[0]?.platform === "s3" ||
                currentCategory?.name ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      position: "absolute",
                    }}
                    onClick={handleModalOpenPlaylist}
                  >
                    Add New Playlist Item
                  </Button>
                ) : (
                  <p>No category selected</p>
                )}

                <PlaylistModal
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  onSubmit={handleSubmitPlaylistItemSave}
                  playlistId={selectedCategoryId}
                  currentCategory={currentCategory}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
