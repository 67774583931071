import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Box } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

/**
 * The `PDFExportComponent` facilitates exporting specified chart elements as a PDF document. It leverages
 * html2canvas for capturing the visual representation of components and jsPDF for generating the PDF.
 * The component dynamically includes filter information in the export.
 * 
 * Upon invoking the export function, it captures each chart by its ID, adjusts for single-row or multi-row layouts,
 * and accounts for custom scaling. It intelligently handles pagination for content that exceeds the page's length.
 *
 * @param {Object} props - The component props.
 * @param {Object} [props.filters={}] - A map of active filters to include in the PDF.
 * @param {Array} props.chartIds - An array of objects containing chart IDs and layout information.
 * @param {string} [props.fileName='exported-document.pdf'] - The default file name for the exported PDF.
 * @returns {React.ReactElement} A button that triggers the export process when clicked.
 */
const PDFExportComponent = ({ filters = {}, chartIds, fileName = 'exported-document.pdf' }) => {
  /**
   * Handles the export button click event by capturing specified charts and compiling them into a PDF.
   * 
   * The function iterates through the chartIds, captures each corresponding chart as an image,
   * and adds the image to a PDF document. It also handles charts that need to be rendered in a single row
   * and adjusts the charts' styles temporarily if necessary to ensure proper capturing.
   * 
   * @async
   * @function handleExportCharts
   */
  const handleExportCharts = async () => {
    const now = new Date();
    const exportDate = now.toLocaleString();
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });
    const filtersDescription = filters ? Object.entries(filters)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`)
      .join(", ") : '';

    let marginTop = 20;
    pdf.setFontSize(10);
    pdf.text(`Created for Admin at ${exportDate}`, 15, marginTop);

    if (filtersDescription) {
      marginTop += 10;
      pdf.text(`Filters: ${filtersDescription}`, 15, marginTop);
    }
    marginTop += 10;

    const pageWidth = pdf.internal.pageSize.getWidth();
    const sideMargin = 30;
    const betweenChartsGap = 5;
    const effectiveWidth = pageWidth - 2 * sideMargin;

    for (let i = 0; i < chartIds.length; i++) {
      const chart = chartIds[i];
      const chartElement = document.getElementById(chart.id);
      if (!chartElement) continue;

      let originalStyles;
      if (chart.ignoreMaxHeight) {
        originalStyles = {
          maxHeight: chartElement.style.maxHeight,
          overflow: chartElement.style.overflow,
        };
        const tableContainerElement = chartElement.querySelector('.MuiTableContainer-root');
        if (tableContainerElement) {
          originalStyles.tableContainerMaxHeight = tableContainerElement.style.maxHeight;
          originalStyles.tableContainerOverflow = tableContainerElement.style.overflow;

          tableContainerElement.style.maxHeight = 'none';
          tableContainerElement.style.overflow = 'visible';
        }
        chartElement.style.maxHeight = 'none';
        chartElement.style.overflow = 'visible';
      }

      const canvas = await html2canvas(chartElement);
      const imageData = canvas.toDataURL('image/png');

      if (originalStyles) {
        chartElement.style.maxHeight = originalStyles.maxHeight;
        chartElement.style.overflow = originalStyles.overflow;
        const tableContainerElement = chartElement.querySelector('.MuiTableContainer-root');
        if (tableContainerElement) {
          tableContainerElement.style.maxHeight = originalStyles.tableContainerMaxHeight;
          tableContainerElement.style.overflow = originalStyles.tableContainerOverflow;
        }
      }

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = effectiveWidth / 2 - betweenChartsGap / 2;
      let imgHeight = imgWidth / aspectRatio;

      if (chart.singleRow) {
        imgWidth = imgWidth * (chart.scale || 1);
        imgHeight = imgWidth / aspectRatio;
      }

      let xPos = sideMargin;
      if (!chart.singleRow && i % 2 === 1) {
        xPos = pageWidth / 2 + betweenChartsGap / 2;
      } else if (chart.singleRow) {
        xPos = (pageWidth - imgWidth) / 2;
      }

      if (marginTop + imgHeight > pdf.internal.pageSize.getHeight() - 20) {
        pdf.addPage();
        marginTop = 20;
      }

      pdf.addImage(imageData, 'PNG', xPos, marginTop, imgWidth, imgHeight);
      if (chart.singleRow || i % 2 === 1) {
        marginTop += imgHeight + 10;
      }
    }

    pdf.save(fileName);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
      <Button variant="contained" color="primary" startIcon={<GetAppIcon />} onClick={handleExportCharts}>
        Export
      </Button>
    </Box>
  );
};

export default PDFExportComponent;
