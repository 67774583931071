import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../components/CircularLoader';
import Cookies from 'js-cookie';

const barChartOptions = {
  chart: {
    id: "basic-bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      distributed: true
    },
  },
  colors: ['#8E9DCC', '#3C4E66'],
  legend: {
    show: false
  },
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return val.toFixed(0);
    }
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    }
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return Math.floor(val);
      }
    }
  },
};

/**
 * CustomerBreakdownChart is a React component that renders a bar chart displaying
 * customer breakdown statistics. It fetches data on component mount and updates its
 * state with this data to render the chart. Displays a loading indicator while fetching
 * and handles any data fetch errors internally.
 */
class CustomerBreakdownChart extends React.Component {
  /**
   * Constructs the CustomerBreakdownChart component with initial state.
   * @param {Object} props - The props passed to the component.
   * 
   * @state {Object} state - The internal state of the component.
   * @state {Array} state.series - The data series for the chart, initially empty.
   * @state {Object} state.options - Configuration options for the ApexCharts chart.
   * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched, initially false.
   */
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: barChartOptions,
      isLoading: false
    };
  }

  /**
   * Invoked immediately after a component is mounted (inserted into the tree).
   * Triggers the initial data fetch for the chart.
   */
  componentDidMount() {
    this.fetchChartData();
  }

  /**
   * Fetches chart data from a specified API endpoint using Axios, updates the component's
   * state with the new data for rendering the chart. Handles loading state and fetch errors.
   */
  fetchChartData = async () => {
    this.setState({ isLoading: true });

    const url = `${process.env.REACT_APP_BASE_URL}admin/customer_breakdown`;
    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };

    try {
      const response = await axios.get(url, config);
      const categories = response.data.map(item => item.label);
      const data = response.data.map(item => item.value);

      this.setState({
        series: [{ name: 'Number of customer', data }],
        options: {
          ...this.state.options,
          xaxis: { categories }
        },
        isLoading: false
      });
    } catch (error) {
      console.error('Error fetching chart data:', error);
      this.setState({ isLoading: false });
    }
  };

  /**
   * Renders the bar chart or a loading/error state based on the current component state.
   * Uses ReactApexChart for rendering the actual chart with the fetched data.
   * 
   * @returns {React.ReactElement} The rendered component.
   */
  render() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '200px'
    };
    const allValuesAreZero = this.state.series.every(value => value === 0);
    return (
      <div id="existing-users-CustomerBreakdownChart" style={this.state.isLoading ? containerStyle : {}}>
        {this.state.isLoading ? (
          <CircularLoader />
        ) : allValuesAreZero ? (
          <div style={containerStyle}>No data matches these filters.</div>
        ) : (
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="95%" />
        )}
      </div>
    );
  }
}

export default CustomerBreakdownChart;
