import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  Box,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import '@ant-design/cssinjs';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import MainCard from "../../components/MainCard";
import RetentionRateChart from './RetentionRateChart';
import CurrentUsersList from './CurrentUsersList';
import CustomerBreakdownChart from './CustomerBreakdownChart';
import DatePickerCard from "../../components/DatePickerCard";
import CircularLoader from '../../components/CircularLoader';
import PDFExportComponent from "../../components/PDFExportComponent";
import Sidebar from "../../menu-items/Sidebar";
/**
 * `ExistingUsersPage` is a React functional component that displays a comprehensive view of existing user metrics.
 * It includes several child components like `CustomerBreakdownChart`, `CurrentUsersList`, and `RetentionRateChart`,
 * which are filtered by certain criteria like location, and sorted by user count or alphabetical order. This component
 * also handles the selection of date ranges for some metrics and exports the data into a PDF report.
 * 
 * Uses the Material UI library for layout and components, Axios for data fetching, and Cookies for authentication.
 * 
 * State Hooks:
 * - iconRotation: Controls the rotation of the sort icon based on the sort order.
 * - filter: Determines the type of users (practice or dentist) to display in the `CurrentUsersList`.
 * - size: Determines the sorting order of the user list (ascending or descending).
 * - locationList: Stores the list of locations fetched from the API.
 * - location: Selected location for filtering the data.
 * - startDate, endDate: Selected date range for the `RetentionRateChart`.
 * 
 * @returns {React.ReactElement} The JSX markup for the `ExistingUsersPage` component.
 */
const ExistingUsersPage = () => {
  const [iconRotation, setIconRotation] = useState(0);
  const [filter, setFilter] = useState('practice');
  const [size, setSize] = useState('descend');
  const [locationList, setLocationList] = useState([{ label: "All locations", value: "all" }]);
  const [location, setLocation] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const chartIds = [
    { id: 'maincard_customer_breakdown', singleRow: true, scale: 1 },
    { id: 'maincard_retention_rates', singleRow: true, scale: 2.1 },
    { id: 'box_current_users', singleRow: true, scale: 0.9},
    { id: 'existing-users-CurrentUsersList', singleRow: true, ignoreMaxHeight: true, scale: 0.9 }
  ];
  const fileName = 'report-existing-users.pdf';

  const toggleSort = () => {
    setSize(prevSlot => prevSlot === 'descend' ? 'ascend' : 'descend');
    setIconRotation(prevRotation => prevRotation === 0 ? 180 : 0);
  };

  const iconStyle = {
    transform: `rotate(${iconRotation}deg)`,
    transition: 'transform 0.3s ease-in-out',
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchLocationList = async () => {
      try {
        const locationURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/locations`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            Authorization: `${token}`
          }
        };

        const locationResponse = await axios.get(locationURL, config);
        if (isMounted) {
          if (locationResponse.data && locationResponse.data.length > 0) {
            const updatedLocationList = [
              { label: "All locations", value: "all" },
              ...locationResponse.data
            ];
            setLocationList(updatedLocationList);
            setLocation(updatedLocationList[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching location data: ', error);
      }
    };

    fetchLocationList();
    return () => {
      isMounted = false;
    };
  }, []);


  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container spacing={2.75}>
        {/* Sidebar on the left */}
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>
  
        {/* Main content on the right */}
        <Grid item xs={12} md={9} lg={10}>
          {/* Customer Breakdown */}
          <MainCard id='maincard_customer_breakdown' content={false} sx={{ height: '225px' }}>
            <Box sx={{ pt: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' } }>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', width: '100%' }}>
                Customer Breakdown
              </Typography>
            </Box>
            <Box sx={{ pb: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CustomerBreakdownChart />
            </Box>
          </MainCard>
  
          {/* Current Users */}
          <Grid container spacing={2.75} mt={1}>
            <Grid item xs={12} md={6}>
              <MainCard sx={{ minHeight: '480px' }}>
                <Box id='box_current_users'>
                  <Box sx={{ pt: 2, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                      Current Users
                    </Typography>
                  </Box>
                  <Box sx={{ p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, margin: 'auto' }}>
                    <TextField
                      id="location-filter"
                      size="small"
                      select
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      sx={{ width: 150, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                    >
                      {locationList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      size="small"
                      onClick={toggleSort}
                      color="primary"
                      variant="outlined"
                    >
                      Sort by size
                      <ArrowDownwardIcon fontSize="inherit" style={iconStyle} />
                    </Button>
                  </Box>
                  <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, margin: 'auto' }}>
                    <ToggleButtonGroup
                      color="primary"
                      size="small"
                      value={filter}
                      exclusive
                      onChange={handleFilterChange}
                      sx={{ width: 'auto' }}
                    >
                      <ToggleButton value="practice" sx={{ width: '100px' }}>Practice</ToggleButton>
                      <ToggleButton value="dentist" sx={{ width: '100px' }}>Dentist</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
                <Box>
                  {locationList.length <= 1 ? (
                    <CircularLoader />
                  ) : (
                    <Box sx={{ height: '100%', pt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
                      <CurrentUsersList location={location} size={size} filter={filter} />
                    </Box>
                  )}
                </Box>
              </MainCard>
            </Grid>
  
            {/* Retention Rates */}
            <Grid item xs={12} md={6}>
              <MainCard id='maincard_retention_rates'>
                <Typography variant="h4" sx={{ pt: 3, fontWeight: 'bold', textAlign: 'center' }}>
                  Retention Rates
                </Typography>
                <Box sx={{ pt: 3, pb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
                  <DatePickerCard onDateChange={handleDateChange} />
                </Box>
                <Box sx={{ height: '100%', pd: 2 }}>
                  <RetentionRateChart startDate={startDate} endDate={endDate} />
                </Box>
              </MainCard>
  
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0 }}>
                <PDFExportComponent
                  chartIds={chartIds}
                  fileName={fileName}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
  
};

export default ExistingUsersPage;