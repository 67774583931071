import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import menuItems from './index';

const Sidebar = () => {
  const { items } = menuItems();

  return (
    <List>
      {items.map((group) =>
        group.children.map((item) => (
          <ListItem button component={Link} to={item.url} key={item.id}>
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))
      )}
    </List>
  );
};

export default Sidebar;
