import React from 'react';
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

/**
 * Renders a card with a date range picker component.
 * 
 * This component allows users to select a range of dates with predefined ranges such as 
 * "Last 7 Days", "Last 14 Days", etc. When a date range is selected or cleared, it calls
 * the `onDateChange` callback with the start and end dates as arguments.
 * 
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.onDateChange - Callback function to be called when the date range changes.
 *   It receives two arguments: the start date and the end date in the format of YYYY-MM-DD. If the date range
 *   is cleared, both arguments are `null`.
 * @param {Object} props.style - Optional style object to customize the appearance of the date range picker.
 * @returns {React.Element} A React Element that renders a date range picker within a space component.
 */
const DatePickerCard = ({ onDateChange, style }) => {
  /**
   * Handles changes in the date range picker. Calls the `onDateChange` callback
   * with formatted date strings or `null` if the selection is cleared.
   * 
   * @param {moment[]} dates - Array of moment.js date objects representing the start and end of the selected range.
   * @param {string[]} dateStrings - Array of date strings formatted as YYYY-MM-DD, representing the start and end of the selected range.
   */
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      onDateChange(dateStrings[0], dateStrings[1]);
    } else {
      onDateChange(null, null);
    }
  };

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];

  return (
    <Space direction="vertical" size={12}>
      <RangePicker presets={rangePresets} onChange={onRangeChange} style={style} />
    </Space>
  );
};

export default DatePickerCard;