import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../components/CircularLoader';
import Cookies from 'js-cookie';

const lineChartOptions = {
  chart: {
    type: 'line',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    toolbar: {
      show: false
    }
  },
  stroke: {
    curve: 'smooth'
  },
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return val.toFixed(0);
    }
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    }
  },
  xaxis: {
    categories: []
  },
  yaxis: {
    min: 0,
    labels: {
      formatter: function (val) {
        return Math.floor(val);
      }
    }
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    offsetY: 0,
    offsetX: 0,
  },
  colors: ['#94BBD4', '#626DA1', '#FFBD88', '#FF7D14']
};

/**
 * `NewSubscribersChart` is a React component that displays a line chart visualizing new subscribers
 * over a given time period based on selected start and end dates. It dynamically fetches the required
 * data upon component mount and when the date range props change, rendering a chart to display the
 * new subscribers' data. While data is being fetched, a loading indicator is shown to the user.
 *
 * The chart utilizes ReactApexChart with custom configuration options defined in `lineChartOptions`.
 * These options include smooth curve lines, customized tooltips, and a specific color palette for the lines.
 */
class NewSubscribersChart extends React.Component {
  /**
   * Initializes the NewSubscribersChart component with its default state.
   * @param {Object} props - The props passed to the component, including `startDate` and `endDate`.
   *
   * @state {Object} state - The internal state of the component.
   * @state {Array} state.series - The data series for the chart.
   * @state {Object} state.options - The chart configuration options.
   * @state {boolean} state.isLoading - Indicates whether the chart data is being fetched.
   */
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: lineChartOptions,
      isLoading: false
    };
  }

  /**
   * Called after the component updates to fetch new data if the `startDate` or `endDate` props have changed.
   * @param {Object} prevProps - Previous props for comparison.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate) {
      this.fetchChartData();
    }
  }

  /**
   * Fetches chart data based on the `startDate` and `endDate` props. Updates the component's
   * state with the new data for rendering the chart or logs an error if the fetch fails.
   */
  fetchChartData = () => {
    this.setState({ isLoading: true });
    const { startDate, endDate } = this.props;
    const url = `${process.env.REACT_APP_BASE_URL}admin/new_subscribers?startDate=${startDate}&endDate=${endDate}`;
    const token = axios.defaults.headers.common.Authorization
    const config = {
      headers: {
        Authorization: `${token}`
      }
    };
    axios.get(url, config)
      .then(response => {
        const { Months, Series } = response.data;
        const series = Series.map(seriesItem => ({
          name: seriesItem.label,
          data: seriesItem.value
        }));
        const updatedOptions = {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: Months
          }
        };
        this.setState({
          series: series,
          options: updatedOptions,
          isLoading: false
        });
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
        this.setState({ isLoading: false });
      });
  }

  /**
   * React lifecycle method called immediately after the component is mounted.
   * It triggers the initial data fetch for the chart.
   */
  componentDidMount() {
    this.fetchChartData();
  }

  /**
   * Renders the line chart using ReactApexChart with the fetched data or displays
   * a loading state while data is being fetched.
   *
   * @returns {React.ReactElement} The rendered component.
   */
  render() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '300px',
      marginLeft: '20px',
      marginRight: '20px'
    };
    const { options, series } = this.state;
    const allValuesAreZero = this.state.series.every(value => value === 0);

    return (
      <div id="new-users-NewSubscribersChart" style={this.state.isLoading ? containerStyle : {}}>
        {this.state.isLoading ? (
          <CircularLoader />
        ) : allValuesAreZero ? (
          <div style={containerStyle}>No data matches these filters.</div>
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={500}
          />
        )}
      </div>
    );
  }
}


export default NewSubscribersChart;