// assets
import {
  HomeOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
  TrophyOutlined,
  ChromeOutlined
} from '@ant-design/icons';

// icons
const icons = {
  HomeOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
  TrophyOutlined,
  ChromeOutlined
};

/**
 * Configuration for dashboard navigation items.
 *
 * This object defines a group of navigation items related to the dashboard functionality of an application.
 * Each item is defined with specific properties like id, title, type, url, and icon to facilitate dynamic rendering
 * of navigation elements. This structure is particularly useful for creating a side navigation menu in a web application.
 *
 * @type {Object} dashboard - The dashboard navigation configuration.
 * @property {string} id - A unique identifier for the group.
 * @property {string} type - The type of navigation element, in this case, a 'group'.
 * @property {Array} children - An array of navigation items within this group.
 * @property {string} children[].id - A unique identifier for each navigation item.
 * @property {string} children[].title - The title of the navigation item.
 * @property {string} children[].type - The type of the navigation item, typically 'item' for individual links.
 * @property {string} children[].url - The URL path that the navigation item links to.
 * @property {JSX.Element} children[].icon - The icon component to be displayed alongside the navigation item.
 * @property {boolean} children[].breadcrumbs - Indicates if breadcrumbs should be shown for this item.
 * @property {boolean} children[].target - Indicates if the link should open in a new tab.
 */
const dashboardUser = {
  id: 'group-dashboard-user',
  type: 'group',
  children: [
    {
      id: 'homepage2',
      title: 'Homepage',
      type: 'item',
      url: '/homepage_user',
      icon: icons.HomeOutlined,
      breadcrumbs: false,
      target: false
    },
    {
      id: 'user-compliance-management',
      title: 'Compliance Management',
      type: 'item',
      url: '/compliance_management_user',
      icon: icons.SafetyCertificateOutlined,
      breadcrumbs: false,
      target: false
    },
    {
      id: 'user-patient-engagement',
      title: 'Patient Engagement',
      type: 'item',
      url: '/patient_engagement_user',
      icon: icons.UserOutlined,
      breadcrumbs: false,
      target: false
    },
    {
      id: 'user-compliance-leaderboard',
      title: 'Compliance Leaderboard',
      type: 'item',
      url: '/compliance_leaderboard_user',
      icon: icons.TrophyOutlined,
      breadcrumbs: false,
      target: false
    },
    {
      id: 'user-help&support',
      title: 'Help & Support',
      type: 'item',
      url: '/help&support_user',
      icon: icons.ChromeOutlined,
      breadcrumbs: false,
      target: false
    }
  ]
};

export default dashboardUser;
