import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import MainCard from "../../components/MainCard";
import ScoreBarChart from './ScoreBarChart';
import ConsentsCompletedChart from './ConsentsCompletedChart';
import ConsentStatusChart from './ConsentStatusBreakdown';
import MonthlyAverageChart from './MonthlyAverageChart';
import Loader from "../../components/Loader";
import PDFExportComponent from "../../components/PDFExportComponent";
import Sidebar from "../../menu-items/Sidebar";
/**
 * CompliancePage component that renders the compliance management dashboard.
 * It fetches and displays lists of practices, clinicians, and locations for filtering,
 * and visualizes compliance data through various charts.
 * 
 * Utilizes several custom components including `MainCard` for card layouts,
 * `ScoreBarChart`, `ConsentsCompletedChart`, `ConsentStatusChart`, and `MonthlyAverageChart`
 * for data visualization, and `PDFExportComponent` for exporting the report to PDF.
 * 
 * State hooks manage the selection states for practices, clinicians, and locations,
 * and their respective lists. Also, it handles the conditional rendering of loaders
 * during data fetch operations.
 */
const CompliancePage = () => {
  const [practiceList, setPracticeList] = useState([{ label: "All practices", value: "all" }]);
  const [practice, setPractice] = useState('all');
  const [clinicianList, setClinicianList] = useState([{ label: "All clinicians", value: "all" }]);
  const [clinician, setClinician] = useState('all');
  const [locationList, setLocationList] = useState([{ label: "All locations", value: "all" }]);
  const [location, setLocation] = useState('all');

  const filters = {
    location: location,
    practice: practice,
    clinician: clinician
  };
  const chartIds = [
    { id: 'maincard_consents_completed', singleRow: false },
    { id: 'maincard_consent_status_breakdown', singleRow: false },
    { id: 'maincard_average_monthly_consents_completed', singleRow: true, scale: 2 }
  ];
  const fileName = 'report-compliance-management.pdf';


  useEffect(() => {
    let isMounted = true;
    const fetchLocationList = async () => {
      try {
        const locationURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/locations`;
        const token = axios.defaults.headers.common.Authorization
        const config = {
          headers: {
            Authorization: `${token}`
          }
        };
        const locationResponse = await axios.get(locationURL, config);
        if (isMounted) {
          if (locationResponse.data && locationResponse.data.length > 0) {
            const updatedLocationList = [
              { label: "All locations", value: "all" },
              ...locationResponse.data
            ];
            setLocationList(updatedLocationList);
            setLocation(updatedLocationList[0].value);
          }
        }
      } catch (error) {
        console.error('Error fetching location data: ', error);
      }
    };

    fetchLocationList();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchPracticeList = async () => {
      try {
        if (location) {
          const practiceURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/practices?location=${location}`;
          const token = axios.defaults.headers.common.Authorization
          const config = {
            headers: {
              Authorization: `${token}`
            }
          };

          const practiceResponse = await axios.get(practiceURL, config);
          if (isMounted) {
            if (practiceResponse.data && practiceResponse.data.length > 0) {
              const updatedPracticeList = [
                { label: "All practices", value: "all" },
                ...practiceResponse.data
              ]
              setPracticeList(updatedPracticeList);
              setPractice(updatedPracticeList[0].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching practice data: ', error);
      }
    };

    fetchPracticeList();
    return () => {
      isMounted = false;
    };
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    const fetchClinicianList = async () => {
      try {
        if (practice) {
          const clinicianURL = `${process.env.REACT_APP_BASE_URL}admin/dropdown/clinicians?practice=${practice}&location=${location}`;
          const token = axios.defaults.headers.common.Authorization
          const config = {
            headers: {
              Authorization: `${token}`
            }
          };

          const clinicianResponse = await axios.get(clinicianURL, config);
          if (isMounted) {
            if (clinicianResponse.data && clinicianResponse.data.length > 0) {
              const updatedClinicianList = [
                { label: "All clinicians", value: "all" },
                ...clinicianResponse.data
              ]
              setClinicianList(updatedClinicianList);
              setClinician(updatedClinicianList[0].value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching clinician data: ', error);
      }
    };

    fetchClinicianList();
    return () => {
      isMounted = false;
    };
  }, [location, practice]);

  if (locationList.length <= 1 || practiceList.length <= 1 || clinicianList.length <= 1) {
    return <Loader />
  }

   return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={2} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={10} lg={10}>
          <Grid container rowSpacing={2.5} columnSpacing={2.75}>
            <Grid item xs={12} md={6} lg={6}>
              <MainCard content={false} sx={{ height: '191px' }}>
                <Box sx={{ p: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    Compliance Management
                  </Typography>
                </Box>
                <Box sx={{ p: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
                  <TextField
                    id="location-filter"
                    size="small"
                    select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    sx={{ width: 200, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {locationList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ p: 2, pb: 4.5, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', my: 4, maxWidth: 600, margin: 'auto' }}>
                  <TextField
                    id="practice-filter"
                    size="small"
                    select
                    value={practice}
                    onChange={(e) => setPractice(e.target.value)}
                    sx={{ width: 130, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {practiceList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="dentist-filter"
                    size="small"
                    select
                    value={clinician}
                    onChange={(e) => setClinician(e.target.value)}
                    sx={{ width: 130, '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                  >
                    {clinicianList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MainCard content={false} sx={{ height: '191px' }}>
                <Box sx={{ p: 4, pb: 0, marginLeft: 2, marginBottom: -2, display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>
                    Compliance Management Score
                  </Typography>
                </Box>
                <ScoreBarChart
                  practice={practice}
                  clinician={clinician}
                  location={location} />
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MainCard id='maincard_consents_completed' content={false} sx={{ height: '280px' }}>
                <Box sx={{ p: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Consents Completed
                  </Typography>
                </Box>
                <Box sx={{ p: 1, pb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <ConsentsCompletedChart
                    practice={practice}
                    clinician={clinician}
                    location={location} />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MainCard id='maincard_consent_status_breakdown' sx={{ height: '375px' }} content={false}>
                <Box sx={{ pt: 3, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Consent Status Breakdown
                  </Typography>
                </Box>
                <Box sx={{ p: 0, pb: 2, mt: -2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <ConsentStatusChart
                    practice={practice}
                    clinician={clinician}
                    location={location} />
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} lg={12}>
              <MainCard id='maincard_average_monthly_consents_completed' content={false} sx={{ height: '670px' }}>
                <Box sx={{ p: 5, pb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Average Monthly Consents Completed
                  </Typography>
                </Box>
                <MonthlyAverageChart
                  practice={practice}
                  clinician={clinician}
                  location={location} />
                <Box sx={{ p: 1.5, pb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}></Box>
              </MainCard>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
                <PDFExportComponent
                  filters={filters}
                  chartIds={chartIds}
                  fileName={fileName}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

export default CompliancePage;
