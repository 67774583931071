import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularLoader from '../../componenets/CircularLoader';
import Cookies from "js-cookie";
const lineChartOptions = {
    chart: {
        type: 'line',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            show: false
        }
    },
    stroke: {
        curve: 'smooth'
    },
    xaxis: {
        type: 'datetime',
        categories: []
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val.toFixed(1);
            }
        }
    },
    legend: {
        position: 'bottom'
    },
    colors: ['#626DA1', '#94BBD4', '#C9C9C9', '#FFBD88', '#FFE5E5'],
    yaxis: {
        labels: {
            formatter: function (val) {
                return Math.round(val);
            }
        }
    }
};

/**
 * `ScoreLineChart` renders a line chart displaying user engagement scores over time for a specified 
 * practice. The component dynamically fetches and visualizes data based on the selected engagement slot 
 * (either 'consent management', 'patient engagement', or 'overall') and the chosen practice. It showcases 
 * how engagement scores have trended, providing valuable insights into user engagement effectiveness.
 *
 * Upon component mounting or when props `slot` or `practice` change, `ScoreLineChart` initiates a data 
 * fetch, updating the visualization accordingly. During data fetching, a loading indicator is displayed, 
 * ensuring a seamless user experience.
 *
 * The chart leverages ReactApexChart with a set of predefined options to enhance the presentation. These 
 * options define the chart type, animation settings, tooltip formatting, and other aspects crucial for 
 * data interpretation. This component is instrumental in illustrating trends in user engagement over time, 
 * serving as a key tool for analysis and decision-making.
 *
 * @param {Object} props - Component props.
 * @param {string} props.slot - The engagement slot to display, affecting which data is fetched and visualized.
 * @param {string} props.practice - The practice ID for which data should be fetched, allowing for focused analysis.
 */
class ScoreLineChart extends React.Component {
    /**
     * Initializes the ScoreLineChart component with its initial state, including empty series data, chart options, 
     * and a loading indicator status.
     *
     * @param {Object} props - Props passed to the component, influencing the fetched data and chart visualization.
     */
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: lineChartOptions,
            isLoading: false
        };
    }

    /**
     * Fetches the chart data from a backend service using Axios. It constructs a URL based on component props,
     * retrieves the data, and updates the component's state to trigger a re-render with the new data.
     *
     * @param {string} url - The URL to fetch the chart data from.
     */
    fetchChartData = (url) => {
        const token = axios.defaults.headers.common.Authorization

        // Add an authorization header with the Bearer token
        const config = {
            headers: {
                "Authorization": `${token}`
            }
        };
        this.setState({ isLoading: true });
        axios.get(url, config)
            .then(response => {
                const apiData = response.data;

                if (apiData.Series && apiData.Series.Labels) {
                    const newSeries = apiData.Series.Labels.map(label => {
                        return {
                            name: label,
                            data: apiData.Months.map((_, monthIndex) => {
                                return apiData.Series.Values[monthIndex][apiData.Series.Labels.indexOf(label)];
                            })
                        };
                    });
                    this.setState({
                        series: newSeries,
                        options: {
                            ...this.state.options,
                            xaxis: {
                                categories: apiData.Months
                            }
                        },
                        isLoading: false
                    });
                } else {
                    console.error('Series or Labels are undefined');
                    this.setState({ isLoading: false });
                }
            })

            .catch(error => {
                console.error('Error fetching chart data:', error);
                this.setState({ isLoading: false });
            });
    }

    /**
     * Constructs the URL for the data fetch based on the current props. It determines the appropriate endpoint
     * to use based on the selected `slot` and appends query parameters for the `practice`.
     *
     * @returns {string} The constructed URL to fetch data from.
     */
    constructUrl = () => {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}users/`;
        let endpoint = "";

        switch (this.props.slot) {
            case 'consent management':
                endpoint = "consent_management_line_chart";
                break;
            case 'patient engagement':
                endpoint = "patient_engagement_line_chart";
                break;
            case 'overall':
                endpoint = "combined_score_line_chart";
                break;
            default:
                endpoint = "consent_management_line_chart";
        }

        return `${baseUrl}${endpoint}?practice=${this.props.practice}`;
    }

    /**
     * Lifecycle method invoked immediately after the component is mounted. It triggers the initial data fetch.
     */
    componentDidMount() {
        this.fetchChartData(this.constructUrl());
    }

    /**
     * Lifecycle method invoked immediately after updating occurs. Triggers a data re-fetch if the `slot` or
     * `practice` props have changed.
     *
     * @param {Object} prevProps - The previous props before the update.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.slot !== this.props.slot || prevProps.practice !== this.props.practice) {
            this.fetchChartData(this.constructUrl());
        }
    }

    /**
     * Renders the line chart or a loading indicator based on the current state. Utilizes ReactApexChart
     * for the chart visualization.
     *
     * @returns {React.ReactElement} - The rendered component.
     */
    render() {
        const containerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            marginLeft: '20px',
            marginRight: '20px'
        };
        const { options, series } = this.state;
        return (
            <div id="user-compliance-leaderboard-ScoreLineChart" style={this.state.isLoading ? containerStyle : { width: '90%' }}>
                {this.state.isLoading ? (
                    <CircularLoader />
                ) : (
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height="350"
                    />
                )}
            </div>
        );
    }
}

export default ScoreLineChart;