import * as React from 'react';
import styles from './Tutorials.module.scss';
import axios from 'axios';

function Tutorials() {
    React.useEffect(() => {
        axios.put('/user/negate_initial_login');
    }, []);
    return (
        <div className={styles.tutorials}>
            <h3>Tutorials</h3>
            <video width="1280" height="720" controls>
                <source src="https://medicube-new.s3.eu-west-2.amazonaws.com/Dentist+explainer+video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}
export default Tutorials;