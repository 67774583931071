import * as React from 'react';
import styles from './PurchaseConfirmation.module.scss';

function Error() {

    return (
            <div className={styles.container}>
                <p>Something Went wrong</p>
            </div>
    );
}
export default Error;